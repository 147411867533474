import { Fragment, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { AuthContext } from "../contexts/authContext";
import {
  ArrowLeftEndOnRectangleIcon,
  ArrowLeftStartOnRectangleIcon,
  ArrowTrendingUpIcon,
  BanknotesIcon,
  Bars3Icon,
  BriefcaseIcon,
  BuildingOffice2Icon,
  BuildingOfficeIcon,
  ChartBarSquareIcon,
  CircleStackIcon,
  CogIcon,
  CurrencyDollarIcon,
  HomeIcon,
  UserCircleIcon,
  UserGroupIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { ChartBarIcon } from "@heroicons/react/24/solid";

const subnavigation = [
  { name: "Marknadsföring", href: "/marketing/targets" },
  { name: "Marknadsföring", href: "/marketing/graphics" },
  { name: "Marknadsföring", href: "/marketing/stats" },
  { name: "Din organisation", href: "/org/users" },
  { name: "Din organisation", href: "/org/invoicing" },
];

const navigation = [
  { name: "Hem", href: "/", icon: HomeIcon },
  // { name: "Lönestatistik", href: "/salaries", icon: ChartBarSquareIcon },
  {
    name: "Marknadsföring",
    href: "/marketing/intro",
    icon: ArrowTrendingUpIcon,
  },

  {
    name: "Din organisation",
    href: "/org/information",
    icon: BuildingOffice2Icon,
  },
  {
    name: "Ditt konto",
    href: "/account",
    icon: UserCircleIcon,
    current: false,
  },

  // {
  //   name: "Fakturering",
  //   href: "/invoices",
  //   icon: BanknotesIcon,
  //   current: false,
  // },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navigation({ pageTitle, sidebarOpen, setSidebarOpen }) {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useContext(AuthContext);

  const path = location.pathname;

  function signOutClicked() {
    auth.signOut();
    navigate("/");
  }

  function navigateRoute(route) {
    navigate(route);
    setSidebarOpen(false);
  }
  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-slate-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div className="flex h-10 mt-5 shrink-0 items-center">
                    <div className="flex">
                      <ChartBarIcon
                        className="h-6 w-6 text-slate-700"
                        aria-hidden="true"
                      />

                      <span className="ml-2 prose sm:block font-bold text-xl text-slate-700">
                        allalöner.se
                      </span>
                    </div>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <a
                                href={"/#" + item.href}
                                className={classNames(
                                  item.current
                                    ? "bg-gray-50 text-slate-600"
                                    : "text-gray-700 hover:text-slate-600 hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal"
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    item.current
                                      ? "text-slate-600"
                                      : "text-gray-400 group-hover:text-slate-600",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                            </li>
                          ))}
                          <li className="border-t pt-2" key={"logout"}>
                            <span
                              onClick={() => signOutClicked()}
                              className={classNames(
                                false
                                  ? "bg-gray-50 text-slate-600"
                                  : "text-gray-700 hover:text-slate-600 hover:bg-gray-50",
                                "cursor-pointer group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium"
                              )}
                            >
                              <ArrowLeftEndOnRectangleIcon
                                className={classNames(
                                  false
                                    ? "text-slate-600"
                                    : "text-gray-400 group-hover:text-slate-600",
                                  "h-6 w-6 shrink-0"
                                )}
                                aria-hidden="true"
                              />
                              Logga ut
                            </span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-3 overflow-y-auto border-r border-gray-200 bg-white px-6">
          <div className="flex h-10 mt-4 shrink-0 items-center">
            <div className="flex flex-shrink-0">
              <div className="flex">
                <ChartBarIcon
                  className="h-6 w-6 text-slate-700"
                  aria-hidden="true"
                />

                <span className=" prose sm:block pl-3 font-bold text-xl text-slate-700">
                  allalöner.se
                </span>
              </div>
            </div>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <a
                        href={"/#" + item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-50 text-slate-700"
                            : "text-gray-700 hover:text-slate-700 hover:bg-gray-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium antialiased"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-slate-700"
                              : "text-gray-400 group-hover:text-slate-700",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    </li>
                  ))}

                  <li className="border-t pt-2" key={"logout"}>
                    <span
                      onClick={() => signOutClicked()}
                      className={classNames(
                        false
                          ? "bg-gray-50 text-slate-600"
                          : "text-gray-700 hover:text-slate-600 hover:bg-gray-50",
                        "cursor-pointer group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium"
                      )}
                    >
                      <ArrowLeftEndOnRectangleIcon
                        className={classNames(
                          false
                            ? "text-slate-600"
                            : "text-gray-400 group-hover:text-slate-600",
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      Logga ut
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-md font-medium leading-6 text-gray-900">
          {navigation.map((i) => (
            <NavLink
              key={i.name}
              // onClick={() => clickNavigate(i)}
              to={i.href}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "block" : "hidden"
              }
            >
              {i.name}
            </NavLink>
          ))}

          {subnavigation.map((i, index) => (
            <NavLink
              key={index + i.name}
              // onClick={() => clickNavigate(i)}
              to={i.href}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "block" : "hidden"
              }
            >
              {i.name}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
}
