import React, { useContext, useEffect } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

import PageNavigation from "../../components/PageNavigation";
import PageTitle from "../../components/PageTitle";
import { AuthContext } from "../../contexts/authContext";

const menuItems = [
  { title: "Introduktion", path: "/marketing/intro" },
  { title: "Kanaler", path: "/marketing/targets" },
  { title: "Grafisk profil", path: "/marketing/graphics" },
  { title: "Statistik & Uppföljning", path: "/marketing/stats" },
];

export default function Marketing() {

  // const { setPageTitle } = useOutletContext({ setPageTitle() });
  const [, setPageTitle] = useOutletContext();

  // setPageTitle("bajs");

  return (
    <div className="lg:pl-60 flex flex-col flex-1 h-full min-h-screen">
      <main className="flex-1 bg-gray-100">
        <PageTitle title={"Marknadsföring"} />
        <PageNavigation items={menuItems} />
        <Outlet />
      </main>
    </div>
  );
}
