import DarkLoader from "./DarkLoader";
import FullWithLoader from "./FullWithLoader";
import LightLoader from "./LightLoader";

export default function SmallDataCard({ title, value, postfix, loading }) {
  return (
    <div className="w-fit bg-white rounded-lg shadow p-4 md:p-4 md:px-6 border">
      <div className="flex mb-2">
        <div>
          <p className="text-sm font-medium text-gray-800 whitespace-nowrap  pb-4">
            {title}
          </p>

          {loading ? (
            <LightLoader />
          ) : (
            <h5 className="leading-none text-xl md:text-3xl font-bold text-gray-900 whitespace-nowrap">
              {value}
              <span className="text-gray-700 text-lg font-normal ml-1 -mt-1 inline-block">
                {postfix}
              </span>
            </h5>
          )}
        </div>
      </div>
    </div>
  );
}
