import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import SmallTextCard from "../../components/TextCard";
import Subtitle from "../../components/Subtitle";
import Card from "../../components/Card";
import Paragraph from "../../components/Paragraph";
import InputText from "../../components/InputText";
import Button from "../../components/Button";
import DarkLoader from "../../components/DarkLoader";
import { CheckIcon } from "@heroicons/react/20/solid";
import SwitchActivation from "../../components/Switch";
import { InformationCircleIcon, StarIcon } from "@heroicons/react/24/solid";
import SmallPill from "../../components/SmallPill";
import { NavLink } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import authenticatedApi from "../../libs/authenticatedRequest";
import { AuthContext } from "../../contexts/authContext";
import {
  checkGraphicStats,
  checkMarketingStatus,
  checkOrgInfo,
} from "./marketingHelpers";
import FullWithLoader from "../../components/FullWithLoader";
import { defaultOrg } from "../../data/models";
import { API_URL } from "../../data/constants";
import { getLastX } from "../../libs/dateHelpers";
import LightLoader from "../../components/LightLoader";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Intro() {
  const [loading, setLoading] = useState(true);
  const [estimatedViews, setEstimatedViews] = useState(0);

  const auth = useContext(AuthContext);
  const [org, setOrg] = useState(defaultOrg);

  useEffect(() => {
    const getOrg = async () => {
      let res = await authenticatedApi.get(
        "/customer/organizations/" + auth.user.orgId,
        { token: auth.sessionInfo.accessToken }
      );
      setOrg(res.data);
      getDailyViews(res.data.SK);
    };

    const getDailyViews = async (orgId) => {
      let res = await axios.get(
        API_URL +
          "customer/organizations/" +
          orgId +
          "/analytics?from=" +
          getLastX(30).from +
          "&to=" +
          getLastX(30).to
      );

      setEstimatedViews(
        res.data.viewsByDate
          .map((v) => v.count)
          .reduce((acc, curr) => acc + curr, 0)
      );
      setLoading(false);
    };

    getOrg();
  }, []);

  const [enabled, setEnabled] = useState(false);

  const steps = [
    {
      name: "Ange organisations- och faktureringsuppgifter",
      description:
        'Till en början behöver ni fylla i all information under "Din Organisation". ',
      href: "/org/information",
      status: checkOrgInfo(org),
      buttonText: "Fyll i information",
    },
    {
      name: "Välj kanaler",
      description:
        "På allalöner väljer du målgrupp genom att specificera vilka yrkerssidor eller tjänster ditt varumärke skall synas på. Du kan tex välja sjuksköterskor, läkare, elektriker, fackförbundstoppen mfl.",
      href: "/marketing/targets",
      status: checkMarketingStatus(org),
      buttonText: "Välj målgrupper",
    },
    {
      name: "Välj grafisk profil",
      description:
        "Här laddar ni upp de bilder och texter ni vill använda i er marknadsföring på allalöner.se. ",
      href: "/marketing/graphics",
      status: checkGraphicStats(org),
      buttonText: "Välj grafisk profil",
    },

    {
      name: "Aktivera marknadsföring",
      description:
        "När allt är ifyllt aktiverar du slutligen er marknadsföring nedan. Inom 24 timmar kommer ert varumärke synas på alla de målgrupper ni valt.",
      status: org.marketing.active === "1" ? true : false,
      activation: true,
    },
    {
      name: "Statistik & Uppföljning",
      description:
        "Du kan följa upp er marknadsföring på statistiksidan eller i mindre utsträckning på startsidan. Vi visar antal visningar per dag för de senaste 7, 30 eller 90 dagarna. Vi följer gärna upp er marknadsföring tillsammans med er för att se vad som går att förbättra.",
      href: "/marketing/stats",
      status: "current",
      buttonText: "Till uppföljning",
    },
  ];

  const activateMarketing = async () => {
    setLoading(true);
    let temp = { ...org };
    temp.marketing.active = temp.marketing.active === "1" ? "0" : "1";

    delete temp.PK;
    delete temp.SK;

    let res = await authenticatedApi.patch(
      "/customer/organizations/" + auth.user.orgId,
      { ...temp },
      { token: auth.sessionInfo.accessToken }
    );

    setOrg({ ...temp });
    setLoading(false);
  };
  return (
    <div className="px-8 bg-gray-100 pb-20 w-fit">
      <div className="flex flex-col xl:flex-row">
        <Card>
          <Subtitle title={"Introduktion"} />
          <Paragraph>
            Genom att visa upp er organisation på allalöner kan ni få fler
            besökare till er sida.
          </Paragraph>

          <nav className="mt-10 max-w-xl w-fit" aria-label="Progress">
            {!loading ? (
              <ol className="overflow-hidden">
                {steps.map((step, stepIdx) => (
                  <li
                    key={step.name}
                    className={classNames(
                      stepIdx !== steps.length - 1 ? "pb-10" : "",
                      "relative"
                    )}
                  >
                    {step.status === true ? (
                      <>
                        {stepIdx !== steps.length - 1 ? (
                          <div
                            className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-emerald-600"
                            aria-hidden="true"
                          />
                        ) : null}
                        <div className="group relative flex items-start">
                          <span className="flex h-9 items-center">
                            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-emerald-600 ">
                              <CheckIcon
                                className="h-5 w-5 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          </span>
                          <span className="ml-4 flex min-w-0 flex-col">
                            <span className="text-sm font-medium">
                              {step.name}
                            </span>
                            <span className="text-sm text-gray-500">
                              {step.description}
                            </span>

                            {step.buttonText ? (
                              <span className="block mt-2">
                                <NavLink to={step.href}>
                                  <Button buttonStyle={"light-small"}>
                                    <ChevronRightIcon className="w-4 h-4 mr-1 -mt-1 inline-block" />

                                    {step.buttonText}
                                  </Button>
                                </NavLink>
                              </span>
                            ) : (
                              ""
                            )}

                            {step.activation ? (
                              <span className="mt-2">
                                <span className="block font-medium text-md text-gray-700">
                                  Aktivera
                                </span>
                                <SwitchActivation
                                  enabled={
                                    org.marketing.active === "1" ? true : false
                                  }
                                  setEnabled={activateMarketing}
                                />
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </>
                    ) : step.status === "current" ? (
                      <>
                        {stepIdx !== steps.length - 1 ? (
                          <div
                            className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                            aria-hidden="true"
                          />
                        ) : null}
                        <div
                          className="group relative flex items-start"
                          aria-current="step"
                        >
                          <span
                            className="flex h-9 items-center"
                            aria-hidden="true"
                          >
                            {stepIdx !== steps.length - 1 ? (
                              <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-600 bg-white">
                                <span className="h-2.5 w-2.5 rounded-full bg-gray-600" />
                              </span>
                            ) : (
                              <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border bg-yellow-400 ">
                                <StarIcon
                                  className="h-5 w-5 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </span>
                          <span className="ml-4 flex min-w-0 flex-col">
                            <span className="text-sm font-medium text-gray-600">
                              {step.name}
                            </span>
                            <span className="text-sm text-gray-500">
                              {step.description}
                            </span>

                            {step.buttonText ? (
                              <span className="block mt-2">
                                <NavLink to={step.href}>
                                  <Button buttonStyle={"light-small"}>
                                    <ChevronRightIcon className="w-5 h-5 mr-1 -mt-1 inline-block" />
                                    {step.buttonText}
                                  </Button>
                                </NavLink>
                              </span>
                            ) : (
                              ""
                            )}

                            {step.activation ? (
                              <span className="mt-2">
                                <span className="block font-medium text-md text-gray-700">
                                  Aktivera
                                </span>
                                <SwitchActivation
                                  enabled={
                                    org.activate.marketing === "1"
                                      ? true
                                      : false
                                  }
                                  setEnabled={activateMarketing}
                                />
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        {stepIdx + 1 === steps.length ? (
                          <div
                            className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                            aria-hidden="true"
                          />
                        ) : null}
                        <NavLink
                          to={step.href}
                          className="group relative flex items-start"
                        >
                          <span
                            className="flex h-9 items-center"
                            aria-hidden="true"
                          >
                            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                              <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                            </span>
                          </span>
                          <span className="ml-4 flex min-w-0 flex-col">
                            <span className="text-sm font-medium text-gray-500">
                              {step.name}
                            </span>
                            <span className="text-sm text-gray-500">
                              {step.description}
                            </span>

                            {step.activation ? (
                              <span className="mt-2">
                                <span className="block font-medium text-md text-gray-700">
                                  Aktivera
                                </span>
                                <SwitchActivation
                                  enabled={
                                    org.marketing.active === "1" ? true : false
                                  }
                                  setEnabled={activateMarketing}
                                />
                              </span>
                            ) : (
                              ""
                            )}

                            {step.buttonText ? (
                              <span className="block mt-2">
                                <Button buttonStyle={"light-small"}>
                                  <ChevronRightIcon className="w-5 h-5 mr-1 -mt-1 inline-block" />
                                  {step.buttonText}
                                </Button>
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </NavLink>
                      </>
                    )}
                  </li>
                ))}
              </ol>
            ) : (
              <FullWithLoader />
            )}
          </nav>

          {/* <Button clickFunction={saveOrg} buttonStyle={"dark"}>
          {loading ? <DarkLoader /> : ""}
          Spara
        </Button> */}
        </Card>

        <div className="h-fit ml-10 max-w-md">
          <Card>
            <div className="flex justify-between">
              <h3 className="text-gray-700 text-lg font-medium">
                Er marknadsföring
              </h3>
              {/* <SwitchActivation setEnabled={setEnabled} enabled={enabled} /> */}
            </div>
            <div className="bg-gray-100 rounded px-4 py-2 flex my-4">
              <p className="text-gray-700 text-sm">
                När ni aktiverat er marknadsföring tar det ungefär 24 timmar
                tills ert varumärke syns på de sidor och målgrupper ni valt.
              </p>{" "}
            </div>
            <hr className="my-4" />
            <h3 className="text-gray-700 text-lg font-medium">
              Valda målgrupper
            </h3>

            <h4 className="text-gray-700 text-sm font-medium mt-4">Yrken</h4>
            <div className="my-2 space-y-1">
              <SmallPill text={"Sjuksköterska"} />
              <SmallPill text={"Röntgensjuksköterska"} />
              <SmallPill text={"Läkare"} />
              <SmallPill text={"Anestesisjuksköterska"} />
              <SmallPill text={"Sjuksköterska"} />
              <SmallPill text={"Skolsköterska"} />
              <span className="block text-sm text-gray-700">+ 12 till</span>
            </div>

            <h4 className="text-gray-700 text-sm font-medium mt-4">Övrigt</h4>
            <div className="my-2">
              <SmallPill text={"Bemanningstoppen"} />
            </div>

            <hr className="my-4" />
            <h3 className="text-gray-700 text-lg font-medium">
              Uppskattade visningar
            </h3>

            <span className="font-medium block mt-4 text-3xl text-gray-700">
              {loading ? <LightLoader /> : estimatedViews + " / månad"}
            </span>

            <div className="bg-gray-100 rounded px-4 py-2 flex my-4">
              <p className="text-gray-700 text-sm">
                Detta är en uppskattning baserad på vår besöksstatistik på de
                yrken ni valt att inkludera i er målgrupp.
              </p>{" "}
            </div>

            <NavLink to={"/marketing/stats"}>
              <button
                type="button"
                className="text-white w-full mt-2 bg-slate-700 hover:bg-slate-800 focus:outline-none  focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 "
              >
                Visa statistik och uppföljning
              </button>
            </NavLink>
          </Card>
        </div>
      </div>
    </div>
  );
}
