import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { NavLink, useNavigate } from "react-router-dom";

export default function PageNavigation({ items }) {
  const navigate = useNavigate();

  const clickNavigate = (item) => {
    navigate(item.path);
  };

  let active =
    " py-1 px-3 text-white font-semibold text-sm underline-offset-2 cursor-pointer underline text-white";
  let notActive =
    "py-1 px-3 text-slate-800 antialiased font-medium prose text-sm cursor-pointer hover:underline text-white";

  return (
    <nav className=" bg-slate-800 text-white tracking-wide pl-3 md:pl-6 py-2 overflow-x-auto flex whitespace-nowrap">
      {items.map((i, index) => (
        <NavLink
        key={index}
          // onClick={() => clickNavigate(i)}
          to={i.path}
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? active : notActive
          }
        >
          {i.title}
        </NavLink>
      ))}
    </nav>
  );
}
