import { useState } from "react";
import CheckBox from "../Checkbox";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import TargetOccupation from "./TargetOccupation";

export default function TargetField({
  field,
  updateSelectedFields,
  selectedFields,
  openFields,
  updateOpenFields,
  selectedOccupations,
  updateSelectedOccupations,
  updateOpenOccupations,
  openOccupations,
  selectedFocuses,
  updateSelectedFocuses,
}) {
  const [checked, setChecked] = useState(false);

  return (
    <div className="bg-white rounded-lg  cursor-pointer flex flex-col text-gray-700 px-4 py-3 my-2 border-2 justify-between">
      <div className="flex justify-between">
        <div
          className="h-10 align-middle pt-1.5"
          onClick={() => updateSelectedFields(field)}
        >
          <span className="mr-4  inline-block">
            <input
              key={field.id}
              id="checked-checkbox"
              type="checkbox"
              checked={selectedFields.includes(field.id)}
              value={selectedFields.includes(field.id)}
              onChange={() => updateSelectedFields(field)}
              className="cursor-pointer border-2 -mt-0.5 inline-block w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 "
            />
          </span>

          <span className="font-medium text-md inline-block">
            {field.name} ({field.occupations.length} grupper)
          </span>
        </div>
        <div>
          <button
            onClick={() => updateOpenFields(field)}
            className="px-3 py-2 border bg-gray-200 rounded text-gray-700 font-medium"
          >
            Visa yrkesgrupper{" "}
            {openFields.includes(field.id) ? (
              <ChevronDownIcon
                stroke="2"
                className="h-5 w-5 text-gray inline-block ml-1"
              />
            ) : (
              <ChevronRightIcon
                stroke="2"
                className="h-5 w-5 text-gray inline-block ml-1"
              />
            )}
          </button>
        </div>
      </div>

      {openFields.includes(field.id) ? (
        <div className=" border-t mt-2 py-4">
          {field.occupations.map((oc) => (
            <TargetOccupation
              key={oc.id}
              selectedOccupations={selectedOccupations}
              updateSelectedOccupations={updateSelectedOccupations}
              updateOpenOccupations={updateOpenOccupations}
              openOccupations={openOccupations}
              occupation={oc}
              field={field}
              selectedFocuses={selectedFocuses}
              updateSelectedFocuses={updateSelectedFocuses}
            />
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
