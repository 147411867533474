import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Card from "../../components/Card";
import Subtitle from "../../components/Subtitle";
import Paragraph from "../../components/Paragraph";
import Table from "../../components/Table";
import Button from "../../components/Button";
import LightLoader from "../../components/LightLoader";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import InputText from "../../components/InputText";

export default function Invoicing() {
  const userTableCols = [
    { title: "Fakturadatum" },
    { title: "Förfallodag" },
    { title: "Belopp" },
    { title: "Betald" },

    { title: "" },
  ];

  const userData = [
    [
      "2024-01-28",
      "2024-02-28",
      "5000 kr",
      <CheckCircleIcon className="w-5 h-5 text-emerald-500" />,
      <Button buttonStyle={"light-small"}>
        Ladda ner{" "}
        <ArrowDownTrayIcon className="text-gray-700 h-4 w-4 inline-block -mt-1" />
      </Button>,
    ],
    [
      "2024-01-28",
      "2024-02-28",
      "5000 kr",
      <XCircleIcon className="w-5 h-5 text-red-500" />,
      <Button buttonStyle={"light-small"}>
        Ladda ner{" "}
        <ArrowDownTrayIcon className="text-gray-700 h-4 w-4 inline-block -mt-1" />
      </Button>,
    ],
    [
      "2024-01-28",
      "2024-02-28",
      "5000 kr",
      <XCircleIcon className="w-5 h-5 text-red-500" />,
      <Button buttonStyle={"light-small"}>
        Ladda ner{" "}
        <ArrowDownTrayIcon className="text-gray-700 h-4 w-4 inline-block -mt-1" />{" "}
      </Button>,
    ],
  ];

  return (
    <div className="px-8">
      <Card>
        <Subtitle title={"Fakturering"} />
        <Paragraph>
          Här hittar du alla era fakturor. Vid frågor vänligen kontakta oss på
          faktura@allaloner.se.
        </Paragraph>

        <div className="flex flex-col space-y-4">
          <div className="max-w-5xl">
            <Table cols={userTableCols} rows={userData} />
          </div>
        </div>
      </Card>
    </div>
  );
}
