import React, { useState, useEffect, useContext } from "react";

import * as cognito from "../libs/cognito";
import authenticatedApi from "../libs/authenticatedRequest";
import { AxiosRequestConfig } from "axios";

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface CustomerUser {
  PK?: string;
  SK?: string;
  orgId?: string;
  GSI1PK?: string;
  GSI1SK?: string;
}

export interface IAuth {
  sessionInfo?: {
    username?: string;
    email?: string;
    sub?: string;
    accessToken?: string;
    refreshToken?: string;
  };
  attrInfo?: any;
  authStatus?: AuthStatus;
  signInWithEmail?: any;
  signUpWithEmail?: any;
  signOut?: any;
  verifyCode?: any;
  getSession?: any;
  sub?: any;
  sendCode?: any;
  forgotPassword?: any;
  changePassword?: any;
  getAttributes?: any;
  setAttribute?: any;
  setUser?: any;
  user?: CustomerUser;
  org?: any;
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
};

type Props = {
  children?: React.ReactNode;
};

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext);
  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [sessionInfo, setSessionInfo] = useState({});
  const [attrInfo, setAttrInfo] = useState([]);
  const [sub, setSub] = useState("");
  const [email, setEmail] = useState("");
  const [user, setUser] = useState<CustomerUser>();
  const [org, setOrg] = useState(null);

  useEffect(() => {
    async function getSessionInfo() {
      try {
        const session: any = await getSession();
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
        });
        window.localStorage.setItem(
          "accessToken",
          `${session.accessToken.jwtToken}`
        );
        window.localStorage.setItem(
          "refreshToken",
          `${session.refreshToken.token}`
        );
        const attr: any = await getAttributes();
        setAttrInfo(attr);
        let sub = attr.find((a: { Name: string }) => a.Name === "sub").Value;
        setSub(sub);

        let config: any = {
          token: session.accessToken.jwtToken,
        };

        if (!user || !user.PK) {
          let res = await authenticatedApi.get(
            "/customer/users/" + sub,
            config
          );
          setUser(res.data);
        }

        console.log(user);
        if (user && user.orgId) {
          let res = await authenticatedApi.get(
            "/customer/organizations/" + user.orgId,
            config
          );
          setOrg(res.data);
        }

        setAuthStatus(AuthStatus.SignedIn);
      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut);
      }
    }
    getSessionInfo();
  }, [setAuthStatus, authStatus]);

  if (authStatus === AuthStatus.Loading) {
    return null;
  }

  async function signInWithEmail(username: string, password: string) {
    try {
      await cognito.signInWithEmail(username, password);

      setAuthStatus(AuthStatus.SignedIn);
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      throw err;
    }
  }

  async function signUpWithEmail(
    username: string,
    email: string,
    password: string
  ) {
    try {
      const result = await cognito.signUpUserWithEmail(
        username,
        email,
        password
      );
      console.log(result);
      return result;
    } catch (err) {
      throw err;
    }
  }

  function signOut() {
    cognito.signOut();
    setUser({});
    setSub("");
    setAuthStatus(AuthStatus.SignedOut);
  }

  async function verifyCode(username: string, code: string) {
    try {
      await cognito.verifyCode(username, code);
    } catch (err) {
      throw err;
    }
  }

  async function getSession() {
    try {
      const session = await cognito.getSession();
      return session;
    } catch (err) {
      throw err;
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes();
      return attr;
    } catch (err) {
      throw err;
    }
  }

  async function setAttribute(attr: any) {
    try {
      const res = await cognito.setAttribute(attr);
      return res;
    } catch (err) {
      throw err;
    }
  }

  async function sendCode(username: string) {
    try {
      await cognito.sendCode(username);
    } catch (err) {
      throw err;
    }
  }

  async function forgotPassword(
    username: string,
    code: string,
    password: string
  ) {
    try {
      await cognito.forgotPassword(username, code, password);
    } catch (err) {
      throw err;
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      await cognito.changePassword(oldPassword, newPassword);
    } catch (err) {
      throw err;
    }
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    sub,
    signUpWithEmail,
    signInWithEmail,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
    setUser,
    user,
    org,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
