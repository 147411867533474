export const employmentTypes = {
  1: { id: 1, display_name: "På plats" },
  2: { id: 2, display_name: "På distans" },
  3: { id: 3, display_name: "Hybrid" },
};

export const salaryTypes = {
  1: { id: 1, display_name_long: "Månadslön", display_name_short: "månad" },
  2: { id: 2, display_name_long: "Timlön", display_name_short: "timme" },
  3: { id: 3, display_name_long: "Årslön", display_name_short: "år" },
};

export const API_URL = "https://api.loner.nu/v1/";
export const API_URL_V0 = "https://api.loner.nu/";
