export const checkOrgInfo = (org) => {
  if (!org.orgName) {
    return false;
  }
  if (!org.contactEmail) {
    return false;
  }
  if (!org.contactPhone) {
    return false;
  }
  if (!org.invoiceEmail) {
    return false;
  }
  if (!org.invoiceReference) {
    return false;
  }

  if (!org.invoiceAddress) {
    return false;
  }
  return true;
};

export const checkMarketingStatus = (org) => {
  if (org.marketing.services.length > 0) {
    return true;
  }
  if (org.marketing.fields.length > 0) {
    return true;
  }
  if (org.marketing.occupations.length > 0) {
    return true;
  }
  if (org.marketing.focuses.length > 0) {
    return true;
  }
  return false;
};

export const checkGraphicStats = (org) => {
  if (!org.smallImg.path) {
    return false;
  }
  if (!org.description) {
    return false;
  }
  return true;
};

export const offers = [
  {
    id: "1",
    displayName: "Bemanningstoppen",
    types: ["1"],
    description:
      "Bemanningstoppen är en av våra tjänster som hjälper besökare hitta bra bemanningsbolag. Det är en kanal där ni når ut till rätt målgrupp.",
    buttonText: "Läs mer om Bemmaningsguiden",
  },
  {
    id: "2",
    displayName: "Fackförbundsguiden",
    types: ["2", "1"],
    description:
      "Fackförbundsguiden är en bra kanal för er som vill marknadsföra ert fackförbund till rätt yrkesgrupper. Ni exponeras som ett rekommenderat fackförbund på relevanta sidor.",
    buttonText: "Läs mer om Fackförbundsguiden",
  },
];
