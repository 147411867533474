import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import { Outlet, useNavigate, useOutletContext } from "react-router-dom";

import { AuthContext } from "../../contexts/authContext";
import { PlusIcon } from "@heroicons/react/24/outline";
import PageNavigation from "../../components/PageNavigation";
import PageTitle from "../../components/PageTitle";

const secret = "10c8cd2a9b44ede796d69a68e3128703";

const menuItems = [
  { title: "Information", path: "/org/information" },
  { title: "Användare", path: "/org/users" },
  { title: "Fakturering", path: "invoicing" },
];

export default function Org() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const API_URL = "https://api.loner.nu/v1/admin";
  const API_URL_V1 = "https://api.loner.nu/v1";
  const [user, setUser] = useState(null);

  useEffect(() => {}, [auth]);

  return (
    <div className="lg:pl-60 flex flex-col flex-1 h-full min-h-screen">
      <main className="flex-1 bg-gray-100">
        <PageTitle title={" Din organisation"} />
        <PageNavigation items={menuItems} />
        <Outlet />
      </main>
    </div>
  );
}
