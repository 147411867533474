export default function Button({ clickFunction, children, buttonStyle }) {
  return (
    <>
      {buttonStyle === "dark" ? (
        <button
          type="button"
          onClick={clickFunction ? () => clickFunction() : undefined}
          className="text-white w-fit bg-gray-800 hover:bg-gray-900 focus:outline-none  focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 "
        >
          {children}
        </button>
      ) : (
        ""
      )}

      {buttonStyle === "light" ? (
        <button
          type="button"
          onClick={clickFunction ? () => clickFunction() : undefined}
          className="text-gray-900 w-fit bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2  "
        >
          {children}
        </button>
      ) : (
        ""
      )}

      {buttonStyle === "light-small" ? (
        <button
          type="button"
          onClick={clickFunction ? () => clickFunction() : undefined}
          className="text-gray-900 w-fit bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-2 "
        >
          {children}
        </button>
      ) : (
        ""
      )}
    </>
  );
}
