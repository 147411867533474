import React, { useContext, useEffect, useState } from "react";
import FullWithLoader from "../../components/FullWithLoader";

import { AuthContext } from "../../contexts/authContext";
import { authenticatedRequest } from "../../libs/authenticatedRequest";
import { XIcon } from "@heroicons/react/outline";
import {
  newSalaryRequestTemplateHtml,
  newSalaryRequestTemplateText,
} from "./templates";

export default function RequestNew() {
  const auth = useContext(AuthContext);
  const API_URL = "https://api.loner.nu/v1";
  const url = API_URL + "/admin/employers";
  const [employers, setEmployers] = useState([]);
  const [filteredEmployers, setFilteredEmployers] = useState([]);
  const [selectedEmployers, setSelectedEmployers] = useState([]);
  const [requestText, setRequestText] = useState(newSalaryRequestTemplateText);
  const [requestHtml, setRequestHtml] = useState(newSalaryRequestTemplateHtml);

  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState("");

  const updateFilteredEmployers = (input) => {
    setInput(input);
    if (input.length > 0) {
      let temp = employers.filter((e) =>
        e.display_name.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredEmployers(temp);
      console.log(temp);
    } else {
      setFilteredEmployers([]);
    }
  };

  const createRequests = async () => {
    if (selectedEmployers.length > 0) {
      setLoading(true);
      for (let e of selectedEmployers) {
        let newRequest = {
          to_address: e.registrator_email,
          from_address: "request@loner.nu",
          from_name: "Löner.nu",
          subject: "Begäran om allmän handling",
          text: requestText,
          text_as_html: requestHtml,
          to_name: e.display_name,
          sent: true,
        };

        let resp = await authenticatedRequest(auth).post(
          API_URL + "/admin/emails",
          newRequest
        );
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    const getDatas = async () => {
      let data = await authenticatedRequest(auth).get(url);

      data = data.filter((i) => i.is_public === 1);

      data.sort((a, b) =>
        a.display_name.toLowerCase() > b.display_name.toLowerCase() ? -1 : 1
      );

      console.log(data);
      setEmployers(data);
      setFilteredEmployers(data);
    };
    getDatas();
  }, [url, auth]);

  const updateSelectedEmployers = (index) => {
    let temp = selectedEmployers.filter((e, i) => i !== index);
    setSelectedEmployers(temp);
  };

  return (
    <div className="md:pl-64">
      <main className="">
        <div className="pt-6">
          <div className="flex justify-between mx-auto px-2 sm:px-6 md:px-4">
            <h1 className="text-2xl font-semibold text-gray-900">
              Ny förfrågan
            </h1>
          </div>
          <div className="px-4 mt-8 font-semibold text-sm">
            <div className=" max-w-3xl">
              <div className="mb-2">Välj myndigheter</div>
              <input
                onChange={(e) => updateFilteredEmployers(e.target.value)}
                type="text"
                value={input}
                className="w-full max-w-xl ring-0 focus:ring-0 border outline-none focus:outline-none"
              />

              {input.length > 2 && (
                <div className="z-10 bg-white max-w-xl w-full absolute border text-gray-700 font-normal">
                  {filteredEmployers.toReversed().map((e, i) => (
                    <div
                      className="border-b py-1 px-2 hover:bg-gray-50 cursor-pointer"
                      onClick={() => {
                        setSelectedEmployers([...selectedEmployers, e]);
                        setInput("");
                      }}
                      key={i}
                    >
                      {e.display_name}
                    </div>
                  ))}
                </div>
              )}

              <div className="mt-3 space-y-1">
                {selectedEmployers.map((e, i) => (
                  <p
                    key={i}
                    className="inline-block whitespace-nowrap px-2 py-1 mr-1 bg-gray-100 border rounded-full font-normal text-xs"
                  >
                    <XIcon
                      onClick={() => updateSelectedEmployers(i)}
                      className="cursor-pointer w-4 h-4 text-gray-700 inline-block -mt-0.5 mr-1"
                    />
                    {e.display_name} -{" "}
                    {e.registrator_email ? (
                      e.registrator_email
                    ) : (
                      <span className="text-red-600">
                        email saknas{" "}
                        <a
                          className="text-blue-600 underline underline-offset-1"
                          target="_blank"
                          rel="noreferrer"
                          href={"/#/employers/" + e.id}
                        >
                          uppdatera
                        </a>
                      </span>
                    )}
                  </p>
                ))}
              </div>
            </div>
            <div className=" max-w-3xl mt-4 flex flex-col">
              <hr />

              {!loading ? (
                <>
                  <div className="mt-4">Meddelande</div>
                  <textarea
                    className="text-gray-700 h-96 text-sm font-normal w-full max-w-xl"
                    disabled={false}
                    value={requestText}
                    onChange={(e) => setRequestText(e.target.value)}
                  >
                    {newSalaryRequestTemplateText}
                  </textarea>

                  <button
                    onClick={() => createRequests()}
                    className="w-fit mt-4 px-3 py-2 bg-blue-600 text-white rounded"
                  >
                    Skicka
                  </button>
                </>
              ) : (
                <FullWithLoader />
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
