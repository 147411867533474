import React, { useContext, useEffect, useState } from "react";
import Subtitle from "../../components/Subtitle";
import Paragraph from "../../components/Paragraph";
import Button from "../../components/Button";
import DarkLoader from "../../components/DarkLoader";
import TargetsTable from "../../components/TargetsTable";
import TargetGroup from "../../components/TargetGroup";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import authenticatedApi from "../../libs/authenticatedRequest";
import { AuthContext } from "../../contexts/authContext";
import { defaultOrg } from "../../data/models";
import { offers } from "./marketingHelpers";
import ServiceTarget from "../../components/OfferTarget";
import OfferTarget from "../../components/OfferTarget";
import Card from "../../components/Card";
import { API_URL, API_URL_V0 } from "../../data/constants";
import axios from "axios";
import FullWithLoader from "../../components/FullWithLoader";
import TargetField from "../../components/targets/TagetField";
import TargetSummary from "../../components/targets/TargetSummary";

let test = {
  1: {
    2: [1, 2, 3],
  },
};

export default function Targets() {
  const [loading, setLoading] = useState(true);
  const [loadingFields, setLoadingFields] = useState(true);

  const auth = useContext(AuthContext);
  const [org, setOrg] = useState(defaultOrg);
  const [fields, setFields] = useState([]);
  const [mTree, setMTree] = useState([]);

  const [filteredFields, setFilteredFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [openFields, setOpenFields] = useState([]);
  const [openOccupations, setOpenOccupations] = useState([]);

  const [occupations, setOccupations] = useState([]);
  const [filteredOccupations, setFilteredOccupations] = useState([]);
  const [focuses, setFocuses] = useState([]);
  const [filteredFocuses, setFilteredFocuses] = useState([]);
  const [selectedOccupations, setSelectedOccupations] = useState([]);
  const [selectedFocuses, setSelectedFocuses] = useState([]);
  const [expandStructure, setExpandStructure] = useState({});

  const updateSelectedFocuses = (focus) => {
    let temp = [...selectedFocuses];
    const index = temp.findIndex((f) => f === focus.id);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(focus.id);
    }
    setSelectedFocuses(temp);
  };

  const updateOpenOccupations = (occupation) => {
    let temp = [...openOccupations];
    const index = temp.findIndex((f) => f === occupation.id);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(occupation.id);
    }
    setOpenOccupations(temp);
  };

  const updateSelectedOccupations = (occupation) => {
    let temp = [...selectedOccupations];
    const index = temp.findIndex((f) => f === occupation.id);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(occupation.id);
    }
    setSelectedOccupations(temp);
  };

  const updateOpenFields = (field) => {
    let temp = [...openFields];
    const index = temp.findIndex((f) => f === field.id);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(field.id);
    }
    setOpenFields(temp);
  };

  const updateSelectedFields = (field) => {
    let temp = [...selectedFields];
    const index = temp.findIndex((f) => f === field.id);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(field.id);
    }
    setSelectedFields(temp);
  };

  const updateServices = (serviceId) => {
    let newOrg = { ...org };
    let exists = newOrg.marketing.services.includes(serviceId);
    if (exists) {
      let index = newOrg.marketing.services.findIndex(
        (item) => item === serviceId
      );
      newOrg.marketing.services.splice(index, 1);
      setOrg(newOrg);
    } else {
      newOrg.marketing.services.push(serviceId);
      setOrg(newOrg);
    }
  };

  useEffect(() => {
    const getOrg = async () => {
      let res = await authenticatedApi.get(
        "/customer/organizations/" + auth.user.orgId,
        { token: auth.sessionInfo.accessToken }
      );

      if (!res.data.marketing) {
        res.data.marketing = {
          services: [1],
          fields: [],
          occupations: [],
          focuses: [],
        };
      } else {
        setSelectedFocuses(res.data.marketing.focuses);
        setSelectedOccupations(res.data.marketing.occupations);
        setSelectedFields(res.data.marketing.fields);
      }

      setOrg(res.data);
      setLoading(false);
    };

    const getOccupationsTree = async () => {
      // let res = await axios.get(API_URL + "/admin/occupations/tree");
      let res = await axios.get(API_URL + "/admin/occupations/marketing/tree");
      res.data.mTree.sort((a, b) => (a.name > b.name ? 1 : -1));
      setFields(res.data.mFields);
      setMTree(res.data.mTree);
      setOccupations(res.data.mOccupations);
      setFocuses(res.data.mFocuses);

      setLoadingFields(false);
    };

    getOrg();
    getOccupationsTree();
  }, []);

  const saveOrg = async () => {
    setLoading(true);
    let temp = { ...org };

    temp.marketing.occupations = selectedOccupations;
    temp.marketing.focuses = selectedFocuses;
    temp.marketing.fields = selectedFields;

    delete temp.PK;
    delete temp.SK;

    let res = await authenticatedApi.patch(
      "/customer/organizations/" + auth.user.orgId,
      { ...temp },
      { token: auth.sessionInfo.accessToken }
    );
    setLoading(false);
  };

  return (
    <div className="px-8 py-8">
      <Subtitle title={"Välj kanaler"} />
      <div className="bg-white shadow-md text-md text-gray-800 rounded px-4 py-2 my-3 border-gray-300 max-w-xl">
        <InformationCircleIcon className="h-5 w-5 text-blue-600 inline-block mr-2 -mt-1" />
        Välj de yrken och tjänster ni vill marknadsföra ert varumärke mot.
      </div>

      <div className="flex mb-5 max-w-5xl">
        <div className="w-full max-w-2xl shrink-0">
          <div className="mt-6">
            <Subtitle title={"Tjänster"} />
            <Paragraph>Klicka på tjänster ni vill lägga till.</Paragraph>
            {offers
              .filter((o) => o.types.includes(org.orgType))
              .map((o) => (
                <div
                  key={o.id}
                  onClick={() => updateServices(o.id)}
                  className={
                    "relative w-full bg-white z-10 rounded-md px-4 py-3 border my-3 hover:bg-gray-50 cursor-pointer " +
                    (org.marketing.services.includes(o.id)
                      ? "outline-slate-700 outline outline-2 border-white"
                      : "")
                  }
                >
                  {org.marketing.services.includes(o.id) ? (
                    <CheckCircleIcon className="text-slate-700 z-20 absolute right-0 top-0 w-7 h-7 " />
                  ) : (
                    <XCircleIcon className="text-gray-400 z-20 absolute right-0 top-0 w-7 h-7 " />
                  )}{" "}
                  <span className="text-lg text-gray-700">{o.displayName}</span>
                  <p className="text-sm text-gray-700 font-light">
                    {o.description}
                  </p>
                  <a
                    className="text-blue-600 underline underline-offset-2  font-light text-sm"
                    href="/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ArrowRightIcon className="w-4 h-4 inline-block -mt-0.5 mr-1" />{" "}
                    {o.buttonText}
                  </a>
                </div>
              ))}
          </div>
          <div className="max-w-3xl w-full mt-10">
            <Subtitle title={"Yrken"} />

            {loadingFields ? (
              <FullWithLoader />
            ) : (
              mTree
                .sort()
                .map((f) => (
                  <TargetField
                    updateSelectedFields={updateSelectedFields}
                    selectedFields={selectedFields}
                    field={f}
                    key={f.id}
                    openFields={openFields}
                    updateOpenFields={updateOpenFields}
                    updateSelectedOccupations={updateSelectedOccupations}
                    selectedOccupations={selectedOccupations}
                    updateOpenOccupations={updateOpenOccupations}
                    openOccupations={openOccupations}
                    selectedFocuses={selectedFocuses}
                    updateSelectedFocuses={updateSelectedFocuses}
                  />
                ))
            )}
          </div>
        </div>

        <div className="ml-20 w-full">
          <div className="mt-5 bg-white text-gray-800 text-md font-normal px-4 py-3 rounded-lg shadow-md">
            <div className="max-w-2xl w-full   ">
              <div className=" px-4 rounded py-2">
                <Subtitle title={"Sammanfattning"} />
                <p className="text-gray-700 pt-2">
                  Här ser du en sammanfattning av tjänster och yrken ni vill
                  synas på. Klicka spara när du är nöjd med ditt val.
                </p>

                <button
                  type="button"
                  onClick={() => saveOrg()}
                  className="text-white w-full mt-2 bg-gray-800 hover:bg-gray-900 focus:outline-none  focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 "
                >
                  Spara
                  {loading ? <DarkLoader /> : ""}
                </button>

                {!loadingFields ? (
                  <div>
                    <h3 className="mt-4 text-md border-t pt-5 font-medium">
                      Valda tjänster
                    </h3>
                    {offers
                      .filter((o) => org.marketing.services.includes(o.id))
                      .map((offer) => (
                        <OfferTarget key={offer.id} offer={offer} />
                      ))}

                    {offers.filter((o) => org.marketing.services.includes(o.id))
                      .length < 1 ? (
                      <p className="text-gray-700 pt-2">
                        Tyvärr inga valda tjänster. Klicka på tjänster till
                        vänster för att välja.
                      </p>
                    ) : (
                      ""
                    )}

                    <div className="border-t px-3 mb-4 mt-10"></div>
                    <h3 className="mt-4 text-md font-medium">Valda yrken</h3>

                    <TargetSummary
                      fields={mTree}
                      occupations={occupations}
                      focuses={focuses}
                      selectedFields={selectedFields}
                      selectedOccupations={selectedOccupations}
                      selectedFocuses={selectedFocuses}
                    />
                  </div>
                ) : (
                  <FullWithLoader />
                )}
                {/* 
                {selectedFields.map((sf) => (
                  <TargetGroup field={fields.find((f) => f.id === sf)} />
                ))} */}

                {/* <TargetGroup field={fields[1]} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
