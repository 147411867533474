import { useEffect, useState } from "react";

export default function TargetSummary({
  selectedFields,
  fields,
  selectedFocuses,
  focuses,
  occupations,
  selectedOccupations,
}) {
  return (
    <div className="flex  flex-col font-medium py-1">
      {selectedFields.map((t) => {
        let field = fields.find((f) => f.id === t);
        return (
          <div key={t} className="flex border-gray-800 flex-col space-y-1 mt-3 bg-white shadow  text-gray-800 text-md font-normal px-3 py-3 pb-4 rounded-lg border">
            <span className="block mb-2 font-medium">{field.name}</span>

            {selectedOccupations.map((o) => {
              let occupation = occupations.find(
                (oc) => oc.id === o && oc.field_id === field.id
              );

              if (occupation) {
                return (
                  <div
                    key={occupation.display_name}
                    className="px-4 mr-1 rounded-lg bg-gray-200 text-gray-800 font-semibold text-sm py-2"
                  >
                    <span className="inline-block">
                      {occupation.display_name}
                    </span>

                    <div className="flex flex-col space-y-2">
                      {selectedFocuses.map((f) => {
                        let focus = focuses.find(
                          (fo) =>
                            fo.id === f &&
                            fo.field_id === field.id &&
                            fo.parent_occupation_id === occupation.id
                        );

                        if (focus) {
                          return (
                            <span
                              key={focus.display_name}
                              className="px-4 shadow mr-1 py-1.5 first:mt-2 last:mb- block  rounded-full bg-white text-gray-700 font-semibold text-xs"
                            >
                              {focus.display_name}
                            </span>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}

            {/* {field.occupations.map((o) => (
              <span
                key={o.name}
                className="px-2 mr-1 py-1  rounded-full bg-blue-500 text-white font-semibold text-xs"
              >
                {o.name}
              </span>
            ))} */}
          </div>
        );
      })}
    </div>
  );
}
