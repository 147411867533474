import { MenuIcon } from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";

import { useNavigate, useOutletContext } from "react-router-dom";

import { AuthContext } from "../../contexts/authContext";
import { authenticatedRequest } from "../../libs/authenticatedRequest";

export default function Settings() {
  const API_URL = "https://api.loner.nu/v1/admin";
  const navigate = useNavigate();
  const [, setSidebarOpen] = useOutletContext();
  const [user, setUser] = useState(null);

  const auth = useContext(AuthContext);

  useEffect(() => {}, [auth]);

  function signOutClicked() {
    auth.signOut();
    navigate("/");
  }

  function changePasswordClicked() {
    navigate("/changepassword");
  }

  return (
    <div className="lg:pl-64 flex flex-col flex-1">
      <main className="flex-1">
        <div className="py-6">
          <div className="max-w-7xl px-4 sm:px-6 md:px-8 mb-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              Inställningar
            </h1>
          </div>
          <div className="max-w-7xl px-4 sm:px-6 md:px-8">
            <div>
              <div>
                <pre className=""></pre>
              </div>
              <pre className="">{JSON.stringify(auth.attrInfo, null, 2)}</pre>

              <div></div>
            </div>

            {/* /End replace */}

            <div className="mt-10">
              <button
                onClick={signOutClicked}
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Logga ut
              </button>
              <button
                onClick={changePasswordClicked}
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Byt lösenord
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
