import { useState } from "react";
import { Outlet } from "react-router-dom";
import Navigation from "../components/navigation";
import Top from "../components/top";

export default function Portal() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState("");

  return (
    <div className="">
      {/* <Top></Top> */}
      <Navigation
        pageTitle={pageTitle}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      <div className="flex flex-col flex-1">
        <div className="">
          <Outlet
            context={[sidebarOpen, setSidebarOpen, setPageTitle]}
          ></Outlet>
        </div>
      </div>
    </div>
  );
}
