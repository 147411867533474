import React from "react";
import "./App.css";

import { HashRouter as Router, Route, Routes } from "react-router-dom";

import AuthProvider, {
  AuthIsSignedIn,
  AuthIsNotSignedIn,
} from "./contexts/authContext";

import SignIn from "./routes/auth/signIn";
import SignUp from "./routes/auth/signUp";
import VerifyCode from "./routes/auth/verify";
import RequestCode from "./routes/auth/requestCode";
import ForgotPassword from "./routes/auth/forgotPassword";
import ChangePassword from "./routes/auth/changePassword";
import Home from "./routes/home";
import Portal from "./routes/Portal";
import Occupations from "./routes/occupations/occupations";
import Employers from "./routes/employers/employers";
import SalaryReferences from "./routes/salary-references/salary-references";
import EmployerCreate from "./routes/employers/employer-create";
import EmployerEdit from "./routes/employers/employer-edit";
import OccupationEdit from "./routes/occupations/occupations-edit";
import OccupationAdd from "./routes/occupations/occupations-add";
import RequestList from "./routes/requests/request-list";
import RequestNew from "./routes/requests/request-new";
import LeadsList from "./routes/leads/leads-list";
import Settings from "./routes/settings/settings";
import Marketing from "./routes/marketing/marketing";
import Salaries from "./routes/salaries/salaries";
import Org from "./routes/org/org";
import Account from "./routes/account/account";
import Information from "./routes/org/information";
import Users from "./routes/org/users";
import Invoicing from "./routes/org/invoicing";
import Presentation from "./routes/marketing/graphics";
import Grafik from "./routes/marketing/graphics";
import Graphics from "./routes/marketing/graphics";
import Targets from "./routes/marketing/targets";
import Intro from "./routes/marketing/intro";
import Stats from "./routes/marketing/stats";

const SignInRoute: React.FunctionComponent = () => (
  <Router>
    <Routes>
      <Route path="/signup" element={<SignUp />} />
      <Route path="/verify" element={<VerifyCode />} />
      <Route path="/requestcode" element={<RequestCode />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/" element={<SignIn />} />
    </Routes>
  </Router>
);

const MainRoute: React.FunctionComponent = () => (
  <Router>
    <Routes>
      <Route path="/changepassword" element={<ChangePassword />} />

      <Route element={<Portal />}>
        {/* <Route path="/settings" element={<Settings />} /> */}
        <Route path="/requests" element={<RequestList />} />
        <Route path="/request/new" element={<RequestNew />} />

        <Route path="/salary-references" element={<SalaryReferences />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/marketing" element={<Marketing />}>
          <Route index element={<Intro />} />
          <Route path="intro" element={<Intro />} />
          <Route path="graphics" element={<Graphics />} />
          <Route path="targets" element={<Targets />} />
          <Route path="stats" element={<Stats />} />
        </Route>
        <Route path="/salaries" element={<Salaries />} />
        <Route path="/org" element={<Org />}>
          <Route index element={<Information />} />
          <Route path="information" element={<Information />} />
          <Route path="users" element={<Users />} />
          <Route path="invoicing" element={<Invoicing />} />
        </Route>
        <Route path="/account" element={<Account />} />

        <Route path="/occupations" element={<Occupations />} />
        <Route path="/occupations/add" element={<OccupationAdd />} />
        <Route path="/occupations/:id" element={<OccupationEdit />} />

        <Route path="/employers" element={<Employers />} />
        <Route path="/employers/add" element={<EmployerCreate />} />
        <Route path="/employers/:id" element={<EmployerEdit />} />

        <Route path="/leads" element={<LeadsList />} />

        {/* 
        <Route path="/support" element={<Support />} />
        <Route path="/projects/create" element={<CreateProject />} /> */}
        <Route path="/" element={<Home />} />
      </Route>
    </Routes>
  </Router>
);

const App: React.FunctionComponent = () => (
  <AuthProvider>
    <AuthIsSignedIn>
      <MainRoute />
    </AuthIsSignedIn>
    <AuthIsNotSignedIn>
      <SignInRoute />
    </AuthIsNotSignedIn>
  </AuthProvider>
);

export default App;
