export const createDateString = (date) => {
  let d = new Date(date);
  return parseInt(d.getDate()) + " " + months[d.getMonth()];
};

export const getLastX = (daysBackInTime) => {
  var today = new Date();
  var priorDate = new Date(
    new Date().setDate(today.getDate() - daysBackInTime)
  );

  return { to: today.yyyymmdd(), from: priorDate.yyyymmdd() };
};

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Maj",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dec",
];
