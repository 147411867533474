import { jwtDecode } from "jwt-decode";
import { useState, useEffect } from "react";
import { refreshSession } from "./cognito";
import axios from "axios";
import { API_URL } from "../data/constants";

const authenticatedApi = axios.create({
  baseURL: API_URL,
});

authenticatedApi.interceptors.request.use(
  (config) => {
    if (config.token) {
      config.headers.Authorization = `Bearer ${config.token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

authenticatedApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        console.log("in refresh session");
        const refreshToken = await refreshSession();

        const newToken = refreshToken.accessToken.jwtToken;
        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${newToken}`;

        let res = await axios(originalRequest);
        return res;
      } catch (error) {
        console.log("refreshtoken error");
        // Handle refresh token error or redirect to login
      }
    }

    return Promise.reject(error);
  }
);

export default authenticatedApi;

export function authenticatedRequest(auth) {
  return {
    get: request("GET"),
    post: request("POST"),
    put: request("PUT"),
    delete: request("DELETE"),
    patch: request("PATCH"),
  };

  function request(method) {
    // if (jwtDecode(auth.sessionInfo.accessToken).exp < Date.now() / 1000) {
    //   console.log("expired");
    //   let res = await refreshSession();
    //   console.log(res);
    // }

    return (url, body) => {
      const requestOptions = {
        method,
        headers: { Authorization: `Bearer ${auth.sessionInfo.accessToken}` },
      };

      if (body) {
        requestOptions.headers["Content-Type"] = "application/json";
        requestOptions.body = JSON.stringify(body);
      }
      return fetch(url, requestOptions).then(handleResponse);
    };
  }

  function handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          // if (jwtDecode(auth.sessionInfo.accessToken).exp < Date.now() / 1000) {
          //   console.log("expired");
          //   let res = refreshSession();
          //   console.log(res);
          // }

          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          console.log("Problem executing request, user should be logged out");
          //   localStorage.removeItem("user");
          //   setAuth(null);
          //   history.push("/login");
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    });
  }
}
