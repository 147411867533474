export default function InputText({
  value,
  label,
  changeFuction,
  placeholder,
  id,
}) {
  return (
    <div className="flex flex-col">
      <label className="font-medium mb-2" htmlFor={id}>
        {label}
      </label>
      <input
        id={id}
        type={"text"}
        onChange={(e) => changeFuction(id, e.target.value)}
        className="border-1 rounded border-gray-700 max-w-md"
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
}
