import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import {
  NavLink,
  Outlet,
  useNavigate,
  useOutletContext,
} from "react-router-dom";

import { AuthContext } from "../../contexts/authContext";
import { PlusIcon } from "@heroicons/react/24/outline";
import Paragraph from "../../components/Paragraph";
import Subtitle from "../../components/Subtitle";
import Card from "../../components/Card";
import Button from "../../components/Button";
import PageTitle from "../../components/PageTitle";
import PageNavigation from "../../components/PageNavigation";

const menuItems = [{ title: "Byta lösenord", path: "/account" }];

export default function Account() {
  const navigate = useNavigate();
  const [, setSidebarOpen] = useOutletContext();
  const [selectedSchool, setSelectedSchool] = useState(null);
  const auth = useContext(AuthContext);
  const API_URL = "https://api.loner.nu/v1/admin";
  const API_URL_V1 = "https://api.loner.nu/v1";

  useEffect(() => {}, [auth]);

  return (
    <div className="lg:pl-60 flex flex-col flex-1  h-screen">
      <main className="flex-1 bg-gray-100">
        <PageTitle title={"Ditt konto"} />
        <PageNavigation items={menuItems} />
        <div className="px-8 bg-gray-100 pb-20 w-fit">
          <Card>
            <Paragraph>
              Här kan du byta lösenord för ditt konto.
              {auth.user.SK}
            </Paragraph>
            <div className="mt-4">
              <NavLink to="/changepassword">
                <Button buttonStyle={"light"}>Byt lönsenord</Button>
              </NavLink>
            </div>
          </Card>
        </div>
      </main>
    </div>
  );
}
