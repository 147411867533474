import { NavLink, useNavigate } from "react-router-dom";

export default function PageTitle({ title }) {
  return (
    <div className="px-8 py-6 hidden lg:block">
      <div className="max-w-4xl">
        <h1 className="text-2xl font-semibold text-gray-800">{title}</h1>
      </div>
    </div>
  );
}
