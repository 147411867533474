import React, { useState, useContext } from "react";

import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../contexts/authContext";
import { createInitialUser } from "../../libs/userService";

const VerifyCode: React.FunctionComponent<{}> = () => {
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");

  const [loading, setLoading] = useState(false);
  const [, setError] = useState("");

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const sendClicked = async () => {
    try {
      setLoading(true);
      await authContext.verifyCode(username, code);
      setLoading(false);
      navigate("/");
    } catch (err) {
      setError("Invalid Code");
    }
  };

  const requestNewCode = async () => {
    navigate("/requestcode");
  };

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Verifiera email
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Ange koden vi skickat till din emailadressen för att verifiera
            kontot.
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-2xl sm:px-10">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-bold text-gray-700"
                >
                  Email:
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    onChange={(e) => setUsername(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="code"
                  className="block text-sm font-bold text-gray-700"
                >
                  Verifieringskod:
                </label>
                <div className="mt-1">
                  <input
                    id="code"
                    name="code"
                    type="text"
                    required
                    onChange={(e) => setCode(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="button"
                  onClick={() => sendClicked()}
                  className="w-full font-bold flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {loading ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                  {loading ? " " : "Verifiera"}
                </button>
              </div>
            </form>
            <div className="flex items-center justify-between mt-3">
              <div className="text-sm">
                <span
                  onClick={requestNewCode}
                  className="font-medium text-primary-600 hover:text-primary-500 cursor-pointer"
                >
                  Skicka ny kod?
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyCode;
