export default function OfferTarget({ offer }) {
  return (
    <div
      key={offer.id}
      className="mt-3 w-full bg-white shadow  text-slate-700 text-md font-normal px-3 py-3 rounded-lg outline outline-1 outline-slate-700"
    >
      <span className="block font-medium">{offer.displayName}</span>
    </div>
  );
}
