import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import { NavLink, useNavigate, useOutletContext } from "react-router-dom";

import { AuthContext } from "../contexts/authContext";
import authenticatedApi, {
  authenticatedRequest,
} from "../libs/authenticatedRequest";
import { unAuthenticatedRequest } from "../libs/unAuthenticatedRequest";
import Chart from "react-apexcharts";
import SmallDataCard from "../components/SmallDataCard";
import DateSelectionDropdown from "../components/DateSelectionDropdown";
import {
  barChartDailyUsers,
  barChartRankingConfig,
} from "../libs/chartConfigs";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/solid";

export default function Home() {
  const navigate = useNavigate();
  const [, setSidebarOpen] = useOutletContext();
  const [selectedSchool, setSelectedSchool] = useState(null);
  const auth = useContext(AuthContext);
  const API_URL = "https://api.loner.nu/v1/admin";
  const API_URL_V1 = "https://api.loner.nu/v1";
  const [user, setUser] = useState(null);

  useEffect(() => {}, [auth]);

  const getUser = async () => {
    let res = await authenticatedApi.get("/customer/users/" + auth.sub, {
      cognito: auth,
    });

    auth.setUser(res.data);
  };

  const createUser = async () => {
    console.log(auth.attrInfo.find((a) => a.Name === "sub").Value);
    console.log(auth.attrInfo.find((a) => a.Name === "email").Value);

    // const user = await authenticatedRequest(auth).post(
    //   "https://api.loner.nu/v1/customer/users",
    //   { email: "lennartj.93@gmail.com", sub: "1234" }
    // );

    // console.log(user);
  };

  return (
    <div className="lg:pl-60 flex flex-col flex-1 h-full min-h-screen bg-gray-100">
      <main className="flex-1">
        <div className="py-6 px-8">
          <div className="max-w-4xl px-4 sm:px-6 md:px-8 mb-8">
            <h1 className="text-2xl font-semibold text-gray-900">Hem</h1>
            <p className="mt-5 bg-white text-gray-800 text-md font-normal px-3 py-3 rounded-lg border shadow">
              Välkommen! Nedan ser du en sammanfattning av visningar ni fått
              genom allalöner.se. Mer utförlig statistik och uppföljning kan ni
              få under{" "}
              <a
                className="underline underline-offset-2"
                href="#/marketing/stats"
              >
                Statistik & Uppföljning.
              </a>{" "}
              Om ni ännu inte satt upp era kanaler och målgrupper kommer ni
              igång under{" "}
              <a
                className="underline underline-offset-2"
                href="#/marketing/intro"
              >
                marknadsföring
              </a>
              .
            </p>
            <div className="flex flex-col space-y-4 mt-10">
              <div className="flex max-w-md rounded-r-full border w-full">
                <div className="bg-yellow-300 px-4 py-2 w-24"></div>
                <NavLink to="/org/information">
                  <div className=" text-lg cursor-pointer hover:bg-gray-50 text-gray-700 font-semibold px-3 py-4 bg-white w-full rounded-r-full">
                    <ChevronDoubleRightIcon className="w-5 h-5 text-gray-700 inline-block mr-3 -mt-1" />
                    Till er organisation
                    <p className="text-sm text-gray-800 font-normal pl-8 pr-6 mt-2">
                      Här sätter ni upp användare, kontaktinfo och
                      faktureringsuppgifter.
                    </p>
                  </div>
                </NavLink>
              </div>
              <div className="flex max-w-md rounded-r-full border w-full">
                <div className="bg-emerald-600 px-4 py-2 w-20"></div>
                <NavLink to="/marketing/intro">
                  <div className=" text-lg cursor-pointer hover:bg-gray-50 text-gray-700 font-semibold px-3 py-4 bg-white w-full rounded-r-full">
                    <ChevronDoubleRightIcon className="w-5 h-5 text-gray-700 inline-block mr-3 -mt-1" />
                    Till marknadsföring
                    <p className="text-sm text-gray-800 font-normal pl-8 pr-6 mt-2">
                      Här sätter ni upp målgrupp, kanaler och grafisk profil.
                    </p>
                  </div>
                </NavLink>
              </div>

              <div className="flex border max-w-md rounded-r-full w-full">
                <div className="bg-blue-500 px-4 py-2 w-24"></div>
                <NavLink to="/marketing/stats">
                  <div className=" text-lg text-gray-700 font-semibold px-3 py-4 cursor-pointer hover:bg-gray-50 bg-white w-full rounded-r-full">
                    <ChevronDoubleRightIcon className="w-5 h-5 text-gray-700 inline-block mr-3 -mt-1" />
                    Till statistik & uppföljning
                    <p className="text-sm text-gray-800 font-normal pl-8 pr-6 mt-2">
                      Här ser ni hur mycket visningar, klick och
                      intresseanmälningar ni fått in.
                    </p>
                  </div>
                </NavLink>
              </div>
            </div>
            {/* <div className="mt-10 bg-white p-4 border rounded-lg shadow mb-40">
              <div className="flex justify-between">
                <span className="pr-2 bg-white text-sm text-gray-700 font-medium">
                  Visningar per dag
                </span>
              </div>
              <div className="relative mb-2 mt-6">
                <Chart {...barChartDailyUsers} />
              </div>
            </div> */}
          </div>
        </div>
      </main>
    </div>
  );
}
