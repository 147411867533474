import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import SmallTextCard from "../../components/TextCard";
import Subtitle from "../../components/Subtitle";
import Card from "../../components/Card";
import Paragraph from "../../components/Paragraph";
import InputText from "../../components/InputText";
import Button from "../../components/Button";
import DarkLoader from "../../components/DarkLoader";
import { CloudArrowUpIcon, PhotoIcon } from "@heroicons/react/24/outline";
import SmallLoader from "../../components/SmallLoader";
import {
  ArrowRightIcon,
  ChevronRightIcon,
  StarIcon,
} from "@heroicons/react/20/solid";
import { NavLink } from "react-router-dom";
import authenticatedApi from "../../libs/authenticatedRequest";
import { AuthContext } from "../../contexts/authContext";
import { defaultOrg } from "../../data/models";

export default function Graphics() {
  const [orgName, setOrgName] = useState("");
  const [org, setOrg] = useState(defaultOrg);
  const auth = useContext(AuthContext);

  const [showDropzone, setShowDropzone] = useState(true);
  const [showLogo, setShowLogo] = useState(true);

  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");

  const [invoiceEmail, setInvoiceEmail] = useState("");
  const [invoiceReference, setInvoiceReference] = useState("");
  const [invoiceAddress, setInvoiceAddress] = useState("");

  const [isLoadingDescription, setIsLoadingDescription] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const API_URL = "https://api.loner.nu/v1/admin";

  useEffect(() => {
    const getOrg = async () => {
      let res = await authenticatedApi.get(
        "/customer/organizations/" + auth.user.orgId,
        { token: auth.sessionInfo.accessToken }
      );
      setOrg(res.data);
      setLoading(false);
    };

    getOrg();
  }, []);

  const uploadImage = async (event) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("fileupload", event.target.files[0]);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const result = (
        await axios.post(API_URL + "/image-upload", formData, config)
      ).data;

      return result;
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const uploadSmallLogo = async (event) => {
    setLoading(true);
    let result = await uploadImage(event);
    let temp = {
      ...org,
      smallImg: { path: result.location },
      logo_small_id: result.id,
    };

    setOrg(temp);
    delete temp.PK;
    delete temp.SK;

    let res = await authenticatedApi.patch(
      "/customer/organizations/" + auth.user.orgId,
      { ...temp },
      { token: auth.sessionInfo.accessToken }
    );
    setLoading(false);
  };

  const saveDescription = async () => {
    setIsLoadingDescription(true);
    await saveOrg();
    setIsLoadingDescription(false);
  };

  const saveOrg = async () => {
    let temp = { ...org };
    delete temp.PK;
    delete temp.SK;

    console.log(temp);

    let res = await authenticatedApi.patch(
      "/customer/organizations/" + auth.user.orgId,
      { ...temp },
      { token: auth.sessionInfo.accessToken }
    );
  };
  return (
    <div className="px-8 max-w-5xl">
      <Card>
        <Subtitle title={"Grafisk profil"} />
        <Paragraph>
          Här får du möjlighet att påverka hur ert varumärke visas i er
          marknadsföring.
        </Paragraph>

        <div className="w-full mt-5 border-t py-5  flex flex-row">
          <div className="flex">
            <div className="border-r pr-10 max-w-md">
              <h3 className="text-gray-700 text-lg font-medium mb-4">
                Ladda upp logga
              </h3>
              <p className="text-sm text-gray-700 mb-4">
                Vi rekommenderar att ladda upp en kvadratisk logga med mått på
                ungefär 180x180
              </p>

              <div className="flex w-full">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col w-80 items-center justify-center border-2 border-gray-300 border-dashed rounded cursor-pointer bg-gray-50  hover:bg-gray-100 "
                >
                  <PhotoIcon className="w-7 h-17 text-gray-500 mt-6" />

                  <p className="text-md text-gray-500  px-6 py-2 pb-6 text-center">
                    Klicka här eller dra hit bild för att ladda upp ny logga.
                  </p>
                  <input
                    onChange={(e) => uploadSmallLogo(e)}
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                  />
                  {isLoading && (
                    <div className="mb-4">
                      {" "}
                      <SmallLoader />
                    </div>
                  )}
                </label>
              </div>
            </div>

            {org.smallImg ? (
              <div className="w-full ml-20">
                <h3 className="text-gray-700 text-lg font-medium mb-4">
                  Vald logga
                </h3>
                <p className="text-sm text-gray-700 mb-4">
                  Den valda loggan kommer att visas tillsammans med
                  organisationens namn i de kanaler ni valt att marknadsföra er
                  i.
                </p>

                <img
                  width={100}
                  className="max-h-50"
                  src={org.smallImg.path}
                  alt="logo"
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Card>
      <Card>
        <div className="">
          <Subtitle title={"Beskrivning"} />
          <Paragraph>
            Beskriv kort er verksamhet med ungefär 50 ord. Denna beskrivning kan
            tas med på de sidor ni visas på.
          </Paragraph>
          <textarea
            id="message"
            rows="4"
            value={org.description ? org.description : ""}
            onChange={(e) => setOrg({ ...org, description: e.target.value })}
            className="max-w-xl mt-4 block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Vi är ett företag med framåtanda..."
          ></textarea>
          <button
            type="button"
            onClick={() => saveDescription()}
            className="text-white w-fit mt-4 bg-gray-800 hover:bg-gray-900 focus:outline-none  focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 "
          >
            Spara beskrivning
            {isLoadingDescription ? <DarkLoader /> : ""}
          </button>
        </div>
      </Card>
      <Card>
        <div className="">
          <Subtitle title={"Såhär visas ni"} />
          <div className="py-3 mb-5">
            <Paragraph>
              Nedan visar vi hur ert varumärke kommer att presenteras på våra
              yrkessidor och tjänster.{" "}
            </Paragraph>
          </div>

          {/* Bemmanningstoppen / Fackförbundstoppen */}
          <div className="border rounded-xl flex">
            <img
              alt="testlogga"
              className="w-auto max-h-20 ml-10 mr-5 my-2"
              src={org.smallImg.path}
            />
            <div className="bg-slate-50 w-full rounded-r-xl py-3 px-4">
              <p className="px-4 text-md w-full text-gray-800 pb-2">
                {org.description
                  ? org.description
                  : "Här dyker din beskrivning upp..."}
              </p>
              <button className="ml-3 px-3 py-1 bg-emerald-600 font-medium text-white rounded-full">
                <ArrowRightIcon
                  className={"w-4 h-4 -mt-0.5 mr-1 text-white inline-block"}
                />
                Läs mer
              </button>
            </div>
          </div>
          {/* Yrkessidor */}
          <div className="rounded-full border max-w-3xl px-3 py-2 mt-10">
            <StarIcon className="w-5 h-5 text-yellow-500 inline-block mr-2 -mt-1" />
            <span className="font-medium">{org.orgName}</span>
            <span className="text-sm">
              {org.orgType === "1"
                ? " - Rekommenderat bemmaningsbolag"
                : " - Rekommenderad arbetsgivare"}{" "}
              för sjuksköterskor!
            </span>
          </div>

          <div className="rounded-full border max-w-3xl px-3 py-2 mt-4">
            <StarIcon className="w-5 h-5 text-yellow-500 inline-block mr-2 -mt-1" />
            <span className="font-medium">{org.orgName}</span>
            <span className="text-sm">
              {org.orgType === "1"
                ? " - Rekommenderat bemmaningsbolag"
                : " - Rekommenderad arbetsgivare"}{" "}
              för läkare!
            </span>
          </div>

          <div className="rounded-full border max-w-3xl px-5 py-2 mt-4 w-fit">
            <span className="block">
              <StarIcon className="w-5 h-5 text-yellow-500 inline-block mr-2 -mt-1" />
              <span className="font-medium">{org.orgName}</span>
            </span>
            <span className="font-normal text-sm ml-7">
              {org.orgType === "1"
                ? " - Rekommenderat bemmaningsbolag"
                : " - Rekommenderad arbetsgivare"}{" "}
              för undersköterskor!
            </span>
          </div>
        </div>
      </Card>
      <div className="mb-40">
        <Card>
          <div className="max-w-xl">
            <Subtitle title={"Sammanfattning"} />
            <Paragraph>
              När du är nöjd med din beskrivning och hur din logga ser ut kan du
              gå tillbaka till introduktionssidan där allt nu bör vara redo. Du
              kan när som helst gå tillbaka hit och ändra inställningar. Det tar
              ungefär 24h för dem att uppdateras.
            </Paragraph>

            <div className="mt-4" />
            <NavLink to="/marketing/intro">
              <Button buttonStyle={"light-small"}>
                <ChevronRightIcon className="w-5 h-5 mr-1 -mt-1 inline-block" />
                Till sammanfattning
              </Button>
            </NavLink>
          </div>
        </Card>
      </div>
    </div>
  );
}
