export default function TargetFocus({
  field,
  occupation,
  focus,
  selectedFocuses,
  updateSelectedFocuses,
}) {
  return (
    <div
      onClick={() => updateSelectedFocuses(focus)}
      className="font-medium py-1"
    >
      <span className="mr-4  inline-block">
        <input
          key={occupation.id}
          id="checked-checkbox"
          type="checkbox"
          checked={selectedFocuses.includes(focus.id)}
          value={selectedFocuses.includes(focus.id)}
          onChange={() => updateSelectedFocuses(focus)}
          className="cursor-pointer border-2 -mt-0.5 inline-block w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 "
        />
      </span>
      <span className="text-xs">{focus.display_name}</span>
    </div>
  );
}
