import React, { useState, useContext } from "react";

import { useNavigate } from "react-router-dom";

import {
  useValidCode,
  useValidPassword,
  useValidUsername,
} from "../../hooks/useAuthHooks";

import { AuthContext } from "../../contexts/authContext";

export default function ForgotPassword() {
  const { code, setCode } = useValidCode("");
  const { password, setPassword } = useValidPassword("");
  const [, setConfirmPassword] = useState("");

  const { username, setUsername } = useValidUsername("");
  const [error, setError] = useState("");
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const resetPassword = async () => {
    try {
      setLoading(true);
      await authContext.forgotPassword(username, code, password);
      setReset(true);
      setLoading(false);
    } catch (err) {
      if (err instanceof Error) {
        setLoading(false);
        setError(err.message);
      }
    }
  };

  const toLogin = () => {
    navigate("/");
  };

  const updatePassword = (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Återställ lösenord
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Vi har skickat en återställningskod till din email. Ange kod, email
            och ditt nya lösenord.
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-2xl sm:px-10">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="code"
                  className="block text-sm font-bold text-gray-700"
                >
                  Återställningskod:
                </label>
                <div className="mt-1">
                  <input
                    id="code"
                    name="code"
                    type="text"
                    required
                    onChange={(e) => setCode(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-bold text-gray-700"
                >
                  Email:
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    onChange={(e) => setUsername(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-bold text-gray-700"
                >
                  Nytt Lösenord:
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    autoComplete="off"
                    name="password"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="confirmpassword"
                  className="block text-sm font-bold text-gray-700"
                >
                  Bekräfta lösenord:
                </label>
                <div className="mt-1">
                  <input
                    id="confirmpassword"
                    autoComplete="off"
                    name="confirmpassword"
                    type="password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="button"
                  onClick={resetPassword}
                  className="w-full font-bold flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {loading ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                  {loading ? " " : "Återställ lösenord"}
                </button>

                <span
                  onClick={() => navigate("/")}
                  className="text-primary-600 cursor-pointer block mt-2"
                >
                  {/* <ArrowNarrowLeftIcon
                    className="-ml-1 mr-2 -mt-1 h-5 w-5 inline"
                    aria-hidden="true"
                  /> */}
                  Tillbaka till inloggning
                </span>

                {error ? (
                  <div className="mt-4 text-red-800 text-center">{error}</div>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );

  const passwordReset = (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Löesnord återställt
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Ditt lösenord är nu återställt. Gå tillbaka till loginsidan och
            försök igen.
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-2xl sm:px-10">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <button
                  type="button"
                  onClick={toLogin}
                  className="w-full font-bold flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Till inloggning
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );

  return <>{!reset ? updatePassword : passwordReset}</>;
}
