import { useEffect, useState } from "react";
import CheckBox from "../Checkbox";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { API_URL } from "../../data/constants";
import TargetFocus from "./TargetFocus";
import FullWithLoader from "../FullWithLoader";

export default function TargetOccupation({
  occupation,
  updateSelectedOccupations,
  selectedOccupations,
  openOccupations,
  updateOpenOccupations,
  field,
  selectedFocuses,
  updateSelectedFocuses,
}) {
  const [loading, setLoading] = useState(true);
  const [focuses, setFocuses] = useState([]);

  const getFocuses = async (occupation) => {
    updateOpenOccupations(occupation);
  };
  return (
    <div className=" ml-2 rounded-lg bg-gray-50 cursor-pointer flex flex-col text-gray-700 px-4 py-1 my-2 border-2 justify-between">
      <div className="flex justify-between">
        <div
          onClick={() => updateSelectedOccupations(occupation)}
          className="h-10 align-middle pt-2"
        >
          <span className="mr-4  inline-block">
            <input
              key={occupation.id}
              id="checked-checkbox"
              type="checkbox"
              checked={selectedOccupations.includes(occupation.id)}
              value={selectedOccupations.includes(occupation.id)}
              onChange={() => updateSelectedOccupations(occupation)}
              className="cursor-pointer border-2 -mt-0.5 inline-block w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 "
            />
          </span>

          <span className="font-medium text-md inline-block">
            {occupation.display_name}
            {occupation.focuses.length > 0
              ? " (" + occupation.focuses.length + " inriktningar)"
              : ""}
          </span>
        </div>
        <div>
          {occupation.focuses.length > 0 ? (
            <button
              onClick={() => getFocuses(occupation)}
              className="px-2 py-1.5 mt-1 text-sm bg-gray-200 rounded text-gray-700 font-medium"
            >
              Visa inriktningar
              {openOccupations.includes(occupation.id) ? (
                <ChevronDownIcon
                  stroke="2"
                  className="h-5 w-5 text-gray inline-block ml-1"
                />
              ) : (
                <ChevronRightIcon
                  stroke="2"
                  className="h-5 w-5 text-gray inline-block ml-1"
                />
              )}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>

      <div>
        {openOccupations.includes(occupation.id) ? (
          <div className="border-t mt-2 py-4">
            <div className="grid grid-cols-2 gap-1 ml-4">
              {occupation.focuses.map((focus) => (
                <TargetFocus
                  key={focus.id}
                  field={field}
                  occupation={occupation}
                  focus={focus}
                  selectedFocuses={selectedFocuses}
                  updateSelectedFocuses={updateSelectedFocuses}
                />
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
