import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import SmallTextCard from "../../components/TextCard";
import Subtitle from "../../components/Subtitle";
import Card from "../../components/Card";
import Paragraph from "../../components/Paragraph";
import InputText from "../../components/InputText";
import Button from "../../components/Button";
import DarkLoader from "../../components/DarkLoader";
import authenticatedApi from "../../libs/authenticatedRequest";
import { AuthContext } from "../../contexts/authContext";
import FullWithLoader from "../../components/FullWithLoader";
import { defaultOrg } from "../../data/models";

export default function Information() {
  const auth = useContext(AuthContext);

  const [org, setOrg] = useState({ ...defaultOrg });
  const [loading, setLoading] = useState(false);

  const saveOrg = async () => {
    setLoading(true);
    await updateOrg();
    setLoading(false);
  };

  const updateOrg = async () => {
    let temp = { ...org };
    delete temp.PK;
    delete temp.SK;

    console.log(temp);

    let res = await authenticatedApi.patch(
      "/customer/organizations/" + auth.user.orgId,
      { ...temp },
      { token: auth.sessionInfo.accessToken }
    );

    console.log(res.data);
  };

  const updateOrgValue = (k, v) => {
    let temp = { ...org, [k]: v };
    setOrg(temp);
  };

  useEffect(() => {
    const getOrg = async () => {
      let res = await authenticatedApi.get(
        "/customer/organizations/" + auth.user.orgId,
        { token: auth.sessionInfo.accessToken }
      );
      setOrg({ ...org, ...res.data });
    };

    getOrg();
  }, []);

  return (
    <div className="px-8">
      <Card>
        <Subtitle title={"Information"} />
        <Paragraph>
          Var vänlig och fyll i nedan information om er organisation och
          fakturering.
        </Paragraph>

        {org.orgName ? (
          <div className="flex flex-col md:flex-row mb-4 justify-between max-w-5xl">
            <div className="flex flex-1 flex-col mt-6 space-y-4 mr-10 ">
              <InputText
                id={"orgName"}
                value={org.orgName}
                changeFuction={updateOrgValue}
                label={"Organisationens namn"}
                placeholder={"Organisationen AB"}
              />

              <InputText
                id={"contactEmail"}
                value={org.contactEmail}
                changeFuction={updateOrgValue}
                label={"Email till kontaktperson"}
                placeholder={"exempel@gmail.com"}
              />

              <InputText
                id={"contactPhone"}
                value={org.contactPhone}
                changeFuction={updateOrgValue}
                label={"Telefon till kontaktperson "}
                placeholder={"Tex +462792664"}
              />
            </div>
            <div className="flex flex-1 flex-col mt-6 space-y-4 ">
              <InputText
                id={"invoiceEmail"}
                value={org.invoiceEmail}
                changeFuction={updateOrgValue}
                label={"Email för fakturor"}
                placeholder={"Tex... faktura@bolaget.com"}
              />

              <InputText
                id={"invoiceReference"}
                value={org.invoiceReference}
                changeFuction={updateOrgValue}
                label={"Fakturareferens"}
                placeholder={"Tex... Johan Johansson"}
              />

              <InputText
                id={"invoiceAddress"}
                value={org.invoiceAddress}
                changeFuction={updateOrgValue}
                label={"Fakturadress"}
                placeholder={"Tex... Fakturagatan 8, 12345 Stockholm"}
              />
            </div>
          </div>
        ) : (
          <FullWithLoader />
        )}

        <Button clickFunction={saveOrg} buttonStyle={"dark"}>
          {loading ? <DarkLoader /> : ""}
          Spara
        </Button>
      </Card>
    </div>
  );
}
