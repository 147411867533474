import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import FullWithLoader from "../../components/FullWithLoader";

import { AuthContext } from "../../contexts/authContext";
import { authenticatedRequest } from "../../libs/authenticatedRequest";
import { unAuthenticatedRequest } from "../../libs/unAuthenticatedRequest";

const secret = "10c8cd2a9b44ede796d69a68e3128703";

const deleteMetadata = (obj) => {
  delete obj.created_at;
  delete obj.updated_at;
  delete obj.id;
  delete obj.salary;
  return obj;
};

export default function OccupationEdit() {
  const auth = useContext(AuthContext);
  const API_URL = "https://api.loner.nu/v1/admin";
  const API_URL_V1 = "https://api.loner.nu/v1";

  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const [occupation, setOccupation] = useState(null);
  const [parent, setParent] = useState(null);

  const editorRef = useRef(null);

  let { id } = useParams();

  useEffect(() => {
    const getDatas = async () => {
      const data = await authenticatedRequest(auth).get(
        API_URL + "/occupations/" + id
      );
      delete data.salary;
      setOccupation(data);

      if (data.parent_occupation_id) {
        const parentOccupation = await authenticatedRequest(auth).get(
          API_URL + "/occupations/" + data.parent_occupation_id
        );
        delete parentOccupation.salary;
        setParent(parentOccupation);
      }

      setInitialLoad(false);
    };
    getDatas();
  }, [id, API_URL]);

  const createOccupation = async () => {
    if (occupation.name && occupation.display_name && occupation.slug) {
      setLoading(true);
      let content = editorRef.current.getContent();
      console.log(content);
      let updatedOccupation = {
        ...occupation,
        tinymce_content: content,
      };
      updatedOccupation = deleteMetadata(updatedOccupation);
      console.log(updatedOccupation);
      try {
        await authenticatedRequest(auth).patch(
          API_URL + "/occupations/" + occupation.id,
          updatedOccupation
        );

        if (parent) {
          let resp2 = await authenticatedRequest(auth).post(
            API_URL_V1 + "/paths/rebuild",
            {
              command: "yrken-slug-inriktning-group",
              slug: parent.slug,
              group: updatedOccupation.slug,
            }
          );

          await unAuthenticatedRequest().get(
            "https://allaloner.se/api/revalidate?secret=" +
              secret +
              "&path=/yrken/" +
              parent.slug +
              "/inriktning/" +
              updatedOccupation.slug
          );
          console.log(resp2);
        } else {
          let resp = await authenticatedRequest(auth).post(
            API_URL_V1 + "/paths/rebuild",
            {
              command: "yrken-slug",
              slug: updatedOccupation.slug,
            }
          );

          await unAuthenticatedRequest().get(
            "https://allaloner.se/api/revalidate?secret=" +
              secret +
              "&path=/yrken/" +
              updatedOccupation.slug
          );
          console.log(resp);
        }

        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };

  const uploadImage = async (event) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("fileupload", event.target.files[0]);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const result = (
        await axios.post(API_URL + "/image-upload", formData, config)
      ).data;

      return result;
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const uploadSmallLogo = async (event) => {
    let result = await uploadImage(event);
    setOccupation({
      ...occupation,
      smallImg: { path: result.location },
      logo_small_id: result.id,
    });
    setLoading(false);
  };

  const uploadBigLogo = async (event) => {
    let result = await uploadImage(event);
    setOccupation({
      ...occupation,
      bigImg: { path: result.location },
      logo_big_id: result.id,
    });
    setLoading(false);
  };

  return (
    <div className="md:pl-64 flex flex-col flex-1">
      <main className="flex-1 min-h-screen">
        <div className="py-6">
          <div className=" mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              Uppdatera yrke
            </h1>
          </div>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <p className="mt-2 text-sm text-gray-700">
                  Här kan du uppdatera ett yrke!
                </p>
              </div>
            </div>
            <div className="mt-8 overflow-x-auto sm:-mx-6 lg:-mx-8"></div>

            <div className="flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                {!initialLoad ? (
                  <div className="inline-block min-w-full py-2 align-middle">
                    <div className="shadow-sm border-t max-w-10xl">
                      <div className="flex flex-row">
                        <div className="px-10 mt-6 flex flex-col mr-10 border-r">
                          {/* <-- Name --> */}
                          <div className="w-full">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Officielt namn (official name)
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                onChange={(e) =>
                                  setOccupation({
                                    ...occupation,
                                    name: e.target.value,
                                  })
                                }
                                value={occupation.name}
                                autoComplete="given-name"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Display Name --> */}
                          <div className="w-full mt-5">
                            <label
                              htmlFor="first-name"
                              className=" block text-sm font-medium text-gray-700"
                            >
                              Visningsnamn (display name)
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                value={occupation.display_name}
                                onChange={(e) =>
                                  setOccupation({
                                    ...occupation,
                                    display_name: e.target.value,
                                  })
                                }
                                autoComplete="given-name"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Slug --!> */}
                          <div className="w-full mt-5">
                            <label
                              htmlFor="username"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Slug
                            </label>
                            <div className=" flex rounded-md shadow-sm">
                              <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                https://loner.nu/yrken/
                              </span>
                              <input
                                type="text"
                                name="slug"
                                id="slug"
                                value={occupation.slug}
                                onChange={(e) =>
                                  setOccupation({
                                    ...occupation,
                                    slug: e.target.value,
                                  })
                                }
                                className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Display Name --> */}
                          <div className="w-full mt-5">
                            <label
                              htmlFor="first-name"
                              className=" block text-sm font-medium text-gray-700"
                            >
                              Emoji 1
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                value={
                                  occupation.emoji_1 ? occupation.emoji_1 : ""
                                }
                                onChange={(e) =>
                                  setOccupation({
                                    ...occupation,
                                    emoji_1: e.target.value,
                                  })
                                }
                                autoComplete="given-name"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Emoji 2 --> */}
                          <div className="w-full mt-5">
                            <label
                              htmlFor="first-name"
                              className=" block text-sm font-medium text-gray-700"
                            >
                              Emoji 2
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                value={
                                  occupation.emoji_2 ? occupation.emoji_2 : ""
                                }
                                onChange={(e) =>
                                  setOccupation({
                                    ...occupation,
                                    emoji_2: e.target.value,
                                  })
                                }
                                autoComplete="given-name"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Display Name --> */}
                          <div className="w-full mt-5">
                            <label
                              htmlFor="first-name"
                              className=" block text-sm font-medium text-gray-700"
                            >
                              SSYK4 ID
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                value={
                                  occupation.ssyk4_id ? occupation.ssyk4_id : ""
                                }
                                onChange={(e) =>
                                  setOccupation({
                                    ...occupation,
                                    ssyk4_id: e.target.value,
                                  })
                                }
                                autoComplete="given-name"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Display Name --> */}
                          <div className="w-full mt-5">
                            <label
                              htmlFor="first-name"
                              className=" block text-sm font-medium text-gray-700"
                            >
                              SSYK5 ID
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                value={
                                  occupation.ssyk5_id ? occupation.ssyk5_id : ""
                                }
                                onChange={(e) =>
                                  setOccupation({
                                    ...occupation,
                                    ssyk5_id: e.target.value,
                                  })
                                }
                                autoComplete="given-name"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Display Name --> */}
                          <div className="w-full mt-5">
                            <label
                              htmlFor="first-name"
                              className=" block text-sm font-medium text-gray-700"
                            >
                              FIELD ID
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                value={
                                  occupation.field_id ? occupation.field_id : ""
                                }
                                onChange={(e) =>
                                  setOccupation({
                                    ...occupation,
                                    field_id: e.target.value,
                                  })
                                }
                                autoComplete="given-name"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Display Name --> */}
                          <div className="w-full mt-5">
                            <label
                              htmlFor="first-name"
                              className=" block text-sm font-medium text-gray-700"
                            >
                              Parent Occupation ID
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                value={
                                  occupation.parent_occupation_id
                                    ? occupation.parent_occupation_id
                                    : ""
                                }
                                onChange={(e) =>
                                  setOccupation({
                                    ...occupation,
                                    parent_occupation_id: e.target.value,
                                  })
                                }
                                autoComplete="given-name"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Description Name --> */}

                          <div className="w-full mt-5">
                            <label
                              htmlFor="about"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Beskrivning
                            </label>
                            <div className="mt-1">
                              <textarea
                                id="about"
                                name="about"
                                rows={3}
                                value={
                                  occupation.description
                                    ? occupation.description
                                    : ""
                                }
                                onChange={(e) =>
                                  setOccupation({
                                    ...occupation,
                                    description: e.target.value,
                                  })
                                }
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">
                              Some sentences about the occupation.
                            </p>
                          </div>
                        </div>
                        <div>
                          <h3 className="mt-10 text-xl font-medium mb-5">
                            Content
                          </h3>
                          <p className="my-5">
                            Lång SEO optimerad text för yrket
                          </p>
                          <Editor
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            branding={false}
                            initialValue={
                              occupation.tinymce_content
                                ? occupation.tinymce_content
                                : ""
                            }
                            init={{
                              document_base_url: "https://allaloner.se/",
                              relative_urls: false,
                              remove_script_host: false,
                              convert_urls: false,
                              table_sizing_mode: "responesive",
                              init_instance_callback: function (editor) {
                                var freeTiny = document.querySelector(
                                  ".tox .tox-notification--in"
                                );
                                freeTiny.style.display = "none";
                              },
                              height: "800",
                              plugins: [
                                "emoticons",
                                "autolink",
                                "link",
                                "wordcount",
                                "autosave",
                                "lists",
                                "advlist",
                                "table",
                                "image",
                                "media",
                                "code",
                              ],
                              images_upload_url: API_URL + "/image-upload",
                              toolbar:
                                "image | code | media | table tabledelete | formatselect | fontsizeselect |  numlist bullist | link | emoticons | autolink | undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  outdent indent | removeformat | wordcount",
                            }}
                          />
                        </div>
                      </div>
                      <div className=" mt-10 ml-10">
                        {!loading ? (
                          <button
                            type="button"
                            onClick={() => createOccupation()}
                            className="inline-flex mt-0 w-54 mb-10 items-center rounded-md border border-gray-300 bg-emerald-600 px-4 py-2 text-base font-bold text-white shadow-sm hover:bg-emerald-500 focus:outline-none"
                          >
                            Uppdatera yrke
                          </button>
                        ) : (
                          <FullWithLoader />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
