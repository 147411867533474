import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import SmallTextCard from "../../components/TextCard";
import Subtitle from "../../components/Subtitle";
import Card from "../../components/Card";
import Paragraph from "../../components/Paragraph";
import InputText from "../../components/InputText";
import Button from "../../components/Button";
import DarkLoader from "../../components/DarkLoader";
import DateSelectionDropdown from "../../components/DateSelectionDropdown";
import {
  barChartDailyUsers,
  barChartDailyViews,
  barChartRankingConfig,
} from "../../libs/chartConfigs";
import Chart from "react-apexcharts";
import SmallDataCard from "../../components/SmallDataCard";
import { API_URL } from "../../data/constants";
import { defaultOrg } from "../../data/models";
import authenticatedApi from "../../libs/authenticatedRequest";
import { AuthContext } from "../../contexts/authContext";
import FullWithLoader from "../../components/FullWithLoader";

Date.prototype.yyyymmdd = function () {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [
    this.getFullYear() + "-",
    (mm > 9 ? "" : "0") + mm + "-",
    (dd > 9 ? "" : "0") + dd,
  ].join("");
};

let months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Maj",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dec",
];

const createDateString = (date) => {
  let d = new Date(date);
  return parseInt(d.getDate()) + " " + months[d.getMonth()];
};

const getLastX = (daysBackInTime) => {
  var today = new Date();
  var priorDate = new Date(
    new Date().setDate(today.getDate() - daysBackInTime)
  );

  return { to: today.yyyymmdd(), from: priorDate.yyyymmdd() };
};

const menuItems = [
  { title: "Senaste 7 dagarna", from: 7 },
  { title: "Senaste 30 dagarna", from: 30 },
  { title: "Senaste 90 dagarna", from: 90 },
];

export default function Stats() {
  const [loading, setLoading] = useState(false);
  const [org, setOrg] = useState(defaultOrg);
  const auth = useContext(AuthContext);

  const [dailyViews, setDailyViews] = useState([]);
  const [fromDate, setFromDate] = useState(getLastX(30).from);
  const [toDate, setToDate] = useState(getLastX(30).to);
  const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[1]);

  const updateRange = (from) => {
    setFromDate(getLastX(from).from);
  };

  useEffect(() => {
    const getOrg = async () => {
      setLoading(true);
      let res = await authenticatedApi.get(
        "/customer/organizations/" + auth.user.orgId,
        { token: auth.sessionInfo.accessToken }
      );
      setOrg(res.data);
      getDailyViews(res.data.SK);
    };

    const getDailyViews = async (orgId) => {
      let res = await axios.get(
        API_URL +
          "customer/organizations/" +
          orgId +
          "/analytics/summaries?from=" +
          fromDate +
          "&to=" +
          toDate
      );

      setDailyViews(res.data.summaries.reverse());
      setLoading(false);
    };

    getOrg();
  }, [fromDate]);

  return (
    <div className="px-8 py-8">
      <div className="flex justify-between max-w-6xl">
        <Subtitle title={"Statistik & Uppföljning"} />

        <DateSelectionDropdown
          menuItems={menuItems}
          selectedMenuItem={selectedMenuItem}
          setSelectedMenuItem={setSelectedMenuItem}
          updateRange={updateRange}
        />
      </div>
      <p className="bg-white tracking-wide rounded-lg border shadow font-medium px-4 py-4 text-gray-800 antialiased text-md max-w-6xl mt-6">
        Här kan ni följa upp hur det går i er marknadsföring på allalöner.se.{" "}
        Välj 7, 30 eller 90 dagar tillbaka i tiden. Om ni har frågor angående
        statistiken svarar vi gärna på dem. Skicka ett email till
        hej@allaloner.se.
      </p>

      <div className="flex flex-wrap mt-10">
        <div className="mr-6 mt-4">
          <SmallDataCard title={"Klick"} value={"22"} postfix={"st"} />
        </div>
        <div className="mr-6 mt-4">
          <SmallDataCard
            title={"Intresseanmälningar"}
            value={"22"}
            postfix={"st"}
          />
        </div>
        <div className="mr-6 mt-4">
          <SmallDataCard
            title={"Visningar bemanningskollen"}
            value={"1200"}
            postfix={"st"}
          />
        </div>
        <div className="mr-6 mt-4">
          <SmallDataCard
            title={"Visningar totalt"}
            loading={loading}
            value={dailyViews
              .map((dw) => dw.count)
              .reduce((acc, curr) => acc + curr, 0)}
            postfix={"st"}
          />
        </div>
      </div>
      <div className="border border-gray-200 my-10 max-w-6xl"></div>

      <div className="mt-10 bg-white p-4 border rounded-lg shadow max-w-6xl">
        <div className="flex flex-col sm:flex-row justify-between">
          <span className="pb-3 sm:pb-0 pr-2 bg-white text-md text-gray-700 font-medium">
            Visningar per dag
          </span>
          <DateSelectionDropdown
            menuItems={menuItems}
            selectedMenuItem={selectedMenuItem}
            setSelectedMenuItem={setSelectedMenuItem}
            updateRange={updateRange}
          />{" "}
        </div>
        <div className="relative mb-2 mt-6">
          {loading ? (
            <FullWithLoader />
          ) : (
            <Chart
              {...barChartDailyViews(
                dailyViews.map((dw) => dw.count),
                dailyViews.map((dw) => createDateString(dw.date))
              )}
            />
          )}
        </div>
      </div>
      <div className="border my-10 max-w-6xl"></div>
      {/* <div className="mt-10 bg-white p-4 border rounded-lg shadow max-w-6xl">
        <div className="flex justify-between">
          <span className="pb-3 sm:pb-0 pr-2 bg-white text-md text-gray-700 font-medium">
            Yrkessidor med flest visningar
          </span>
          <DateSelectionDropdown
            menuItems={menuItems}
            selectedMenuItem={selectedMenuItem}
            setSelectedMenuItem={setSelectedMenuItem}
            updateRange={updateRange}
          />{" "}
        </div>
        <div className="relative mb-2 mt-6">
          <Chart {...barChartRankingConfig} />
        </div>
      </div> */}
    </div>
  );
}
