import React, { useState, useContext } from "react";

import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../contexts/authContext";

export default function ChangePassword() {
  const [error, setError] = useState("");
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  const auth = useContext(AuthContext);
  const changePassword = async () => {
    try {
      setLoading(true);
      await authContext.changePassword(oldPassword, newPassword);
      setReset(true);
      setLoading(false);
    } catch (err: any) {
      setError(err.message);
      setLoading(false);
    }
  };

  const signOut = async () => {
    try {
      authContext.signOut();
      navigate("/");
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const updatePassword = (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Byt lösenord
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Ange det nya lösenordet för att gå vidare.
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-2xl sm:px-10">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-bold text-gray-700"
                >
                  Tidigare Lösenord:
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    autoComplete="off"
                    name="password"
                    type="password"
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="confirmpassword"
                  className="block text-sm font-bold text-gray-700"
                >
                  Nytt lösenord:
                </label>
                <div className="mt-1">
                  <input
                    id="confirmpassword"
                    autoComplete="off"
                    name="confirmpassword"
                    type="password"
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="button"
                  onClick={changePassword}
                  className="w-full font-bold flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {loading ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                  {loading ? " " : "Återställ lösenord"}
                </button>

                <span
                  onClick={() => navigate("/")}
                  className="text-primary-600 cursor-pointer block mt-2"
                >
                  {/* <ArrowNarrowLeftIcon
                    className="-ml-1 mr-2 -mt-1 h-5 w-5 inline"
                    aria-hidden="true"
                  /> */}
                  Tillbaka
                </span>

                {error ? (
                  <div className="mt-4 text-red-800 text-center">{error}</div>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );

  const passwordReset = (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Lösenord uppdaterat
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Var vänlig logga in igen med det nya lösenordet.
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-2xl sm:px-10">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <button
                  type="button"
                  onClick={signOut}
                  className="w-full font-bold flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {loading ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                  {loading ? " " : "Logga in"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );

  return <>{!reset ? updatePassword : passwordReset}</>;
}
