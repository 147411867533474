import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import { Outlet, useNavigate, useOutletContext } from "react-router-dom";

import { AuthContext } from "../../contexts/authContext";
import PageTitle from "../../components/PageTitle";
import PageNavigation from "../../components/PageNavigation";
import Card from "../../components/Card";
import Paragraph from "../../components/Paragraph";
import Subtitle from "../../components/Subtitle";

const menuItems = [];

export default function Salaries() {
  const navigate = useNavigate();
  const [, setSidebarOpen] = useOutletContext();
  const [selectedSchool, setSelectedSchool] = useState(null);
  const auth = useContext(AuthContext);
  const API_URL = "https://api.loner.nu/v1/admin";
  const API_URL_V1 = "https://api.loner.nu/v1";
  const [user, setUser] = useState(null);

  useEffect(() => {}, [auth]);

  const menuItems = [
    { title: "Introduktion", path: "/salaries" },
    { title: "Lönesök", path: "/salaries/search" },
    { title: "SCB statistik", path: "/salaries/scb" },
  ];

  return (
    <div className="lg:pl-60 flex flex-col flex-1  h-screen">
      <main className="flex-1 bg-gray-100">
        <PageTitle title={"Lönestatistik"} />
        <PageNavigation items={menuItems} />
        {/* <Outlet /> */}
        <div className="px-8 bg-gray-100 pb-20 w-fit max-w-4xl">
          <Card>
            <Subtitle title={"Intro"} />

            <Paragraph>
              Som användare i vår tjänst får du även tillgång till den
              lönestatistik som bygger upp allalöner.se. Du kan använda
              statistiken i ditt arbete med journalistik, lönecoachning eller i
              er rekrytering.
            </Paragraph>
          </Card>
        </div>
      </main>
    </div>
  );
}
