export const defaultOrg = {
  orgName: "",
  contactEmail: "",
  contactPhone: "",
  invoiceEmail: "",
  invoiceReference: "",
  invoiceAddress: "",
  orgType: "",
  description: "",
  smallImg: {
    path: "",
  },

  marketing: {
    active: "0",
    services: [],
    occupations: [],
    focuses: [],
    fields: [],
  },
};
