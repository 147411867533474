import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Paragraph from "../../components/Paragraph";
import Card from "../../components/Card";
import Subtitle from "../../components/Subtitle";
import Button from "../../components/Button";
import DarkLoader from "../../components/DarkLoader";
import Table from "../../components/Table";
import LightLoader from "../../components/LightLoader";
import { PlusCircleIcon } from "@heroicons/react/24/outline";

const menuItems = [
  { title: "Information", path: "/org/information" },
  { title: "Användare", path: "/org/users" },
  { title: "Fakturering", path: "invoicing" },
];

const userTableCols = [
  { title: "Namn" },
  { title: "Roll" },
  { title: "Email" },
];

const userData = [["Lennart Johansson", "Admin", "lennartj.93@gmail.com"]];

export default function Users() {
  const [orgName, setOrgName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const saveOrg = async () => {
    setLoading(!loading);
  };

  return (
    <div className="px-8">
      <Card>
        <Subtitle title={"Användare"} />
        <Paragraph>
          Här kan du se alla användare ni lagt till. Du kan lägga till fler
          användare genom två steg.
        </Paragraph>

        <ul className="my-3">
          <li>
            1. Be dem registrera sig på{" "}
            <a
              className="text-blue-600"
              target="_blank norefferer"
              href="portal.allaloner.se/auth/registrera"
            >
              https://portal.allaloner.se/auth/registrera
            </a>
          </li>
          <li>2. Lägg sedan till dem här</li>
        </ul>
        <Paragraph>De kommer då få tillgång till er organisation.</Paragraph>
        <div className="flex flex-col space-y-4">
          <Table cols={userTableCols} rows={userData} />
          <hr />
          <Button clickFunction={saveOrg} buttonStyle={"light"}>
            {loading ? <LightLoader /> : ""}
            Lägg till
          </Button>
        </div>
      </Card>
    </div>
  );
}
